import { defaultky, rjky } from '@/ky';

export default {
  async fetchContacts(context, filters) {
    return rjky.get(`contacts?${filters}`).json();
  },

  async updateContact(context, payload) {
    return rjky.patch(`contacts/${payload.id}`, { json: payload }).json();
  },

  async uploadAvatar(context, payload) {
    const formData = new FormData();
    formData.append('AVATAR_FILE', payload.file);
    const { uploadedFilePath } = await rjky
      .post(`contacts/${payload.id}/uploadAvatar`, { body: formData }).json();
    return uploadedFilePath;
  },

  async lookupPhone(context, phone) {
    try {
      const response = await defaultky.get(`https://lookups.twilio.com/v1/PhoneNumbers/${phone}`, {
        headers: {
          Authorization: 'Basic QUMwOWJhMjlhY2E3Mzk0YzQwZDIwMGNhZWIwZDUzN2UzZjo2ODNlOTU5NzgxYWVhZWUxOTM0MmJiNzRmNWRhNDM0ZQ==',
        },
      }).json();
      return response.phone_number || phone;
    } catch (error) {
      return phone;
    }
  },
};
