import * as types from '@/store/modules/funnels/mutation_types';

export default {
  [types.SET_FUNNELS](state, funnels) {
    state.funnels = funnels;
  },

  [types.ADD_FUNNEL](state, funnel) {
    state.funnels.push(funnel);
  },

  [types.SET_FUNNEL](state, funnel) {
    state.funnel = funnel;
  },

  [types.DELETE_FUNNEL](state, id) {
    state.funnels = state.funnels.filter((f) => f.id !== id);
  },

  [types.UPDATE_FUNNEL_PROP](state, { prop, value }) {
    state.funnel[prop] = value;
  },

  [types.SET_OFFER_IMAGE](state, image) {
    state.media.offerImage = image;
  },
  [types.SET_FUNNEL_VALID](state, valid) {
    state.valid = valid;
  },
};
