import actions from '@/store/modules/auth/actions';
import getters from '@/store/modules/auth/getters';
import mutations from '@/store/modules/auth/mutations';

export default {
  namespaced: true,
  state: {
    user: {},
    role: '',
    permissions: {},
    authenticated: false,
  },
  getters,
  mutations,
  actions,
};
