import { tap } from '@/utils';
import * as types from './mutation_types';

export default {
  [types.SET_COMPANIES](state, companies) {
    state.companies = companies;
  },
  [types.SET_ARCHIVED_COMPANIES](state, companies) {
    state.archivedCompanies = companies;
  },
  [types.SET_COMPANY](state, company) {
    state.company = company;
    state.locations = company.locations || [];
    state.users = company.users || [];
    state.plan = company.plan || {};
    state.addOns = company.companyPlans || [];
  },
  [types.SET_USERS_COUNT](state, value) {
    state.usersCount = value;
  },
  [types.SET_LOCATIONS_COUNT](state, value) {
    state.locationsCount = value;
  },
  [types.SET_SMS_STATUS](state, sms) {
    state.sms = sms;
  },
  [types.UNSET_COMPANY](state) {
    state.company = null;
    state.users = [];
    state.locations = [];
    state.surveys = [];
    state.surveyTemplates = [];
  },
  [types.SET_COMPANY_CATEGORIES](state, categories) {
    state.categories = categories;
  },
  [types.SET_COMPANY_PLANS](state, plans) {
    state.plans = plans;
  },
  [types.SET_PLAN](state, plan) {
    state.plan = plan;
  },
  [types.SET_APPLICATIONS](state, applications) {
    state.applications = applications;
  },
  [types.SET_COMPANY_DETAILS](state, details) {
    state.company = {
      ...state.company,
      ...details,
    };

    const { companies } = state;
    const index = companies.findIndex((c) => c.id === state.company.id);
    if (index < 0) {
      return;
    }
    companies[index] = {
      ...companies[index],
      ...details,
    };
    state.companies = JSON.parse(JSON.stringify(companies));
  },
  [types.SET_COMPANY_EMAIL_SIGNATURE](state, {
    emailSignatureOne, emailSignatureTwo, emailSignatureThree, emailSignatureFour,
  }) {
    state.company.emailSignatureOne = emailSignatureOne;
    state.company.emailSignatureTwo = emailSignatureTwo;
    state.company.emailSignatureThree = emailSignatureThree;
    state.company.emailSignatureFour = emailSignatureFour;
  },
  [types.SET_LOCATIONS](state, locations) {
    state.locations = locations;
    state.company.locations = locations;
  },
  [types.UNSET_LOCATIONS](state) {
    state.locations = [];
  },
  [types.SET_LOCATION](state, location) {
    state.location = location;
  },
  [types.UNSET_LOCATION](state) {
    state.location = null;
  },
  [types.SET_COMPANY_LOGO](state, fileName) {
    state.company.logo = fileName;
  },
  [types.SET_COMPANY_USERS](state, users) {
    state.users = users;
  },
  [types.UNSET_COMPANY_USERS](state) {
    state.users = [];
  },
  [types.SET_COMPANY_SURVEYS](state, surveys) {
    state.surveys = surveys;
  },
  [types.SET_SURVEY_TEMPLATES](state, templates) {
    state.surveyTemplates = templates;
  },
  [types.UNSET_SURVEY_TEMPLATES](state) {
    state.surveyTemplates = [];
  },
  [types.ADD_COMPANY_SURVEY_TEMPLATE](state, template) {
    state.surveyTemplates.push(template);
  },
  [types.DELETE_COMPANY_SURVEY_TEMPLATE](state, id) {
    state.surveyTemplates = state.surveyTemplates.filter((t) => t.id !== id);
  },
  [types.ADD_USER_TO_COMPANY](state, user) {
    state.users = JSON.parse(
      JSON.stringify([...state.users, user]),
    );
  },
  [types.REPLACE_USER_IN_COMPANY](state, user) {
    const { users } = state;
    const index = users.findIndex((u) => u.id === user.id);
    users[index] = user;
    state.users = JSON.parse(JSON.stringify(users));
  },
  [types.SET_COMPANY_STATS](state, stats) {
    state.stats = stats;
  },
  [types.UNSET_COMPANY_STATS](state) {
    state.stats = null;
  },
  [types.SET_OFFER_IMAGE_URL](state, { surveyTemplateId, fileName }) {
    const surveyTemplate = state.surveyTemplates.find((t) => t.id === surveyTemplateId);
    if (!surveyTemplate) {
      return;
    }
    surveyTemplate.offerImage = fileName;
  },
  [types.SET_COMPANY_LOCATION_DETAILS](state, updatedLocation) {
    const index = state.locations.findIndex((l) => l.id === updatedLocation.id);
    if (index < 0) {
      return;
    }
    state.locations[index] = {
      ...state.locations[index],
      ...updatedLocation,
    };
  },
  [types.SET_REVIEW_SITES](state, reviewSites) {
    state.reviewSites = reviewSites;
  },
  [types.UPDATE_LOCATION_FACEBOOK_PAGE](state, matches) {
    for (const match of matches) {
      const location = state.locations.find((l) => l.id === match.id);
      location.facebookPageId = match.facebookPageId;
    }
  },
  [types.SET_FACEBOOK_CONNECTED](state, isConnected) {
    const facebookApp = state.applications.find((app) => app.code === 'facebook');
    if (!facebookApp) {
      return;
    }
    facebookApp.connected = isConnected;
  },
  [types.SET_FACEBOOK_AUTH_RESPONSE](state, authResponse) {
    const facebookApp = state.applications.find((app) => app.code === 'facebook');
    if (!facebookApp) {
      return;
    }
    facebookApp.auth = authResponse;
  },
  [types.SET_GOOGLE_CONNECTED](state, isConnected) {
    const googleApp = state.applications.find((app) => app.code === 'google');
    if (!googleApp) {
      return;
    }
    googleApp.connected = isConnected;
  },
  [types.SET_GOOGLE_ACCESS_TOKEN](state, token) {
    const index = state.applications.findIndex((app) => app.code === 'google');
    state.applications[index].accessToken = token;
  },
  [types.SET_INFUSIONSOFT_ACCESS_TOKEN](state, { accessToken, appId, cloudBridgeCredentialsId }) {
    const index = state.applications.findIndex((app) => app.code === 'infusionsoft');
    tap(state.applications[index], (app) => {
      state.applications.splice(index, 1, {
        ...app,
        accessToken,
        appId,
        cloudBridgeCredentialsId,
      });
    });
  },
  [types.SET_INFUSIONSOFT_TAGGING](state, isTagging) {
    const index = state.applications.findIndex((app) => app.code === 'infusionsoft');
    tap(state.applications[index], (app) => {
      state.applications.splice(index, 1, {
        ...app,
        tagging: isTagging,
      });
    });
  },
  [types.SET_INFUSIONSOFT_CONNECTED](state, isConnected) {
    const app = state.applications.find((app) => app.code === 'infusionsoft');
    if (!app) {
      return;
    }
    app.connected = isConnected;
  },
  [types.SET_ACTIVE_CAMPAIGN_CONNECTED](state, isConnected) {
    const activeCampaignApp = state.applications.find((app) => app.code === 'activecampaign');
    if (!activeCampaignApp) {
      return;
    }
    activeCampaignApp.connected = isConnected;
  },
  [types.SET_ACTIVE_CAMPAIGN_WEBHOOKS](state, webhooks) {
    const index = state.applications.findIndex((app) => app.code === 'activecampaign');
    state.applications[index].webhooks = webhooks;
  },
  [types.SET_APP_TOKEN](state, { code, token }) {
    const index = state.applications.findIndex((app) => app.code === code);
    tap(state.applications[index], (app) => {
      state.applications.splice(index, 1, {
        ...app,
        token,
        connected: !!token,
      });
    });
  },
  [types.SET_APP_MANAGING](state, { code, isManaging }) {
    const index = state.applications.findIndex((app) => app.code === code);
    tap(state.applications[index], (app) => {
      state.applications.splice(index, 1, {
        ...app,
        managing: isManaging,
      });
    });
  },
  [types.SET_PLAN_UPGRADED](state, upgraded) {
    state.planUpgraded = upgraded;
  },
  [types.SET_ERROR](state, error) {
    state.error = error;
  },
  [types.UNSET_ERROR](state) {
    state.error = null;
  },
};
