import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    error: null,
    industryData: null,
    partner: null,
    partnerId: null,
    user: null,
    userId: null,
  },
  getters,
  mutations,
  actions,
};
