import * as types from './mutation_types';

export default {
  [types.SET_ERROR](state, error) {
    state.error = error;
  },
  [types.UNSET_ERROR](state) {
    state.error = null;
  },
};
