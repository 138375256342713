/*
 * File Name: main.js
 * Description: main vue(js) file
 * Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
 * Author: Pixinvent
 * Author URL: http://www.themeforest.net/user/pixinvent
 */

import Vue from 'vue';
import Vuesax from 'vuesax';
import { VueHammer } from 'vue2-hammer';
import VeeValidate from 'vee-validate';
import VueSocialSharing from 'vue-social-sharing';
import GAuth from 'vue-google-oauth2';
import vueTabevents from 'vue-tabevents';
import middleware from '@/http/middleware/kernel';
import App from '@/App.vue';
import 'material-icons/iconfont/material-icons.css'; // Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
import { formatCentsAsDollars } from '@/utils';

// Filters
import '@/filters/filters';

// Theme Configurations
import '../themeConfig';

// Globally Registered Components
import '@/globalComponents';

// Styles: SCSS
import '@/assets/scss/main.scss';

// Tailwind
import '@/assets/css/main.css';

// Vue Router
import router from '@/router';

// Vuex Store
import store from '@/store/store';

// PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

// Init middleware protection
middleware(router, store);

// Vue.prototype.$http = ky;
Vue.use(Vuesax);
Vue.use(VueHammer);
Vue.use(VeeValidate);
Vue.use(VueSocialSharing, {
  networks: {
    email: 'mailto:?subject=@t&body=@d%0D%0A@u',
    sms: 'sms:?body=@d%0D%0A@u',
  },
});

const googleAuthOptions = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'https://www.googleapis.com/auth/plus.business.manage',
  prompt: 'consent',
  access_type: 'offline',
};

Vue.use(GAuth, googleAuthOptions);

Vue.use(vueTabevents);

VeeValidate.Validator.localize({
  en: {
    messages: {
      email: (field) => `The ${field} field must be a valid email address`,
    },
  },
});

// Feather font icon
require('@/assets/css/iconfont.css');

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.mixin({
  methods: {
    formatCentsAsDollars,
  },
});

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

export default app;
