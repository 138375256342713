import mutations from '@/store/modules/companies/mutations';
import actions from '@/store/modules/companies/actions';
import getters from '@/store/modules/companies/getters';

export const getDefaultState = () => ({
  error: null,
  applications: [],
  categories: [],
  company: {},
  companies: [],
  archivedCompanies: [],
  users: [],
  locations: [],
  surveyTemplates: [],
  surveys: [],
  stats: null,
  location: null,
  plan: null,
  plans: [],
  addOns: [],
  sms: { used: 0, max: 0 },
  reviewSites: [],
  reviews: [],
  usersCount: 0,
  locationsCount: 0,
  planUpgraded: false,
});

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  getters,
  mutations: {
    ...mutations,
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions,
};
