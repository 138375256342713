import * as types from './mutation_types';

export default {
  [types.SET_ERROR](state, error) {
    state.error = error;
  },
  [types.UNSET_ERROR](state) {
    state.error = null;
  },
  [types.SET_CATEGORIES](state, categories) {
    state.categories = categories;
  },
  [types.SET_REVIEW_SITES](state, reviewSites) {
    state.reviewSites = reviewSites;
  },
  [types.SET_TIMEZONES](state, timezones) {
    state.timezones = timezones;
  },
  [types.SET_PLAN_FEATURES](state, features) {
    state.features = features.reduce((obj, feature) => ({
      ...obj, [feature.planId]: { ...obj[feature.planId], [feature.name]: feature.value },
    }), {});
  },
};
