export default {
  error: (state) => state.error,
  categories: (state) => {
    const notFoundCategory = state.categories.find((c) => c.name === 'Category Not Found');
    let categories = [...state.categories];
    if (notFoundCategory) {
      categories = categories.filter((c) => c.id !== notFoundCategory.id);
    }
    categories.sort((a, b) => a.name.localeCompare(b.name));
    if (notFoundCategory) {
      categories.push(notFoundCategory);
    }
    return categories;
  },
  timezones: (state) => state.timezones,
  features: (state) => state.features,
  reviewSites: (state) => [...state.reviewSites].sort((a, b) => a.name.localeCompare(b.name)),
};
