import * as types from '@/store/modules/widgets/mutations_types';

export default {
  [types.SET_WIDGET](state, widget) {
    state.widget = widget;
  },
  [types.SET_DEFAULT_WIDGET](state) {
    state.widget = {
      name: 'A New Widget',
      designConfig: {
        design: 'standard',
        layout: 'single',
        width: 400,
        branding: true,
        mobile: true,
        boundingBox: true,
        displayTime: 7000,
        delay: 2000,
        position: 'left',
        autoplay: true,
      },
      sourceConfig: {
        sort: 'most-recent',
        daysAgo: 90,
        reviews: true,
        testimonials: true,
        minChars: 50,
        minRating: 0.8,
        quantity: 6,
      },
    };
  },

  [types.UPDATE_WIDGET_PROP](state, { key, value }) {
    state.widget[key] = value;
  },

  [types.UPDATE_SOURCE_CONFIG](state, { key, value }) {
    state.widget.sourceConfig[key] = value;
  },

  [types.UPDATE_DESIGN_CONFIG](state, { key, value }) {
    state.widget.designConfig[key] = value;
  },

  [types.SET_DEFAULT_DESIGN_CONFIG](state, overrides) {
    const config = state.widget.designConfig;
    state.widget.designConfig = { ...config, ...overrides };
  },

  [types.CACHE_INITIAL_PROPS](state, { sourceConfig, designConfig, name }) {
    state.old.name = name;
    state.old.designConfig = { ...designConfig };
    state.old.sourceConfig = { ...sourceConfig };
  },
};
