import Vue from 'vue';

/**
 * Global event bus.
 *
 * @type {Vue}
 */
export const bus = new Vue();

/**
 * Checks if the object is empty.
 *
 * @param  {Object} obj
 * @return {Boolean}
 */
export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

/**
 * Check is two objcets are the same.
 *
 * @param  {Object}  original
 * @param  {Object}  old
 * @return {Boolean}
 */
export function isPristine(original, old) {
  const hasOwnProperty = (prop) => Object.prototype.hasOwnProperty.call(old, prop);

  return Object.keys(original).every((key) => hasOwnProperty(key) && original[key] === old[key]);
}

/**
 * Builds the url query string
 *
 * @param  {Object} data
 * @return {string}
 */
export function httpBuildQuery(data) {
  return Object.entries(data).map(([key, val]) => `${key}=${val}`).join('&');
}

/**
 * Creates an array of elements split into groups the length of size.
 *
 * @param  {array} input
 * @param  {int} size
 * @return {array}
 */
export function chunk(input, size) {
  return input.reduce((arr, item, idx) => {
    if (idx % size === 0) {
      return [...arr, [item]];
    }

    return [...arr.slice(0, -1), [...arr.slice(-1)[0], item]];
  }, []);
}

/**
 * Currency formater.
 *
 * @param  {int} cents
 * @return {string}
 */
export function formatCentsAsDollars(cents) {
  const dollars = Math.abs(cents) / 100;
  const str = dollars.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (cents < 0) ? `(${str})` : str;
}

export function formatE164Friendly(e164PhoneNumber) {
  // e164PhoneNumber should look like +[country_code][number]
  // "+11234567890"
  if (!e164PhoneNumber) {
    return '';
  }
  // US/CA
  const usMatch = e164PhoneNumber.match(/\+1(\d{10})$/);
  if (usMatch) {
    const number = usMatch[1];
    return `(${number.substring(0, 3)}) ${number.substring(3, 6)}-${number.substring(6)}`;
  }
  // Australia
  const auMatch = e164PhoneNumber.match(/\+61(\d{9})$/);
  if (auMatch) {
    const number = auMatch[1];
    return `0${number.substring(0, 3)} ${number.substring(3, 6)} ${number.substring(6)}`;
  }
  return e164PhoneNumber;
}

/**
 * StudlyCase / PascalCase a string
 *
 * @param  {string} str
 * @return {string}
 */
export function studly(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => word.toUpperCase()).replace(/\s+/g, '');
}

/**
 * Checks whether the object has the specified property as its own property.
 *
 * @param  {Object} obj
 * @param  {string} key
 * @return {Boolean}
 */
export function hasKey(obj, key) {
  return {}.hasOwnProperty.call(obj, key);
}

export function tap(value, fn) {
  fn(value);
  return value;
}
