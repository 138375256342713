import { tap } from '@/utils';
import * as types from '@/store/modules/users/mutation_types';

export default {
  [types.SET_USERS](state, users) {
    state.users = users.map(({ roleMappings, ...user }) => ({
      ...user, role: roleMappings ? roleMappings[0].role : null,
    }));
  },

  [types.SET_USER](state, user) {
    const { role } = user.updatedRoleMappings[0];
    state.users.push({ ...user, role });
  },

  [types.UPDATE_USER](state, { userId, attributes }) {
    const index = state.users.findIndex((usr) => usr.id === userId);

    tap(state.users[index], (user) => {
      state.users.splice(index, 1, { ...user, ...attributes });
    });
  },

  [types.DELETE_USER](state, userId) {
    state.users = state.users.filter((u) => u.id !== userId);
  },

  [types.SET_ERROR](state, error) {
    state.error = error;
  },

  [types.UNSET_ERROR](state) {
    state.error = null;
  },
};
