export default {
  notifications: (state) => state.notifications.map((n, index) => ({
    index,
    type: n.type,
    title: n.title || 'Notification',
    msg: n.text,
    icon: 'MessageSquareIcon',
    time: new Date(n.createdAt),
    category: 'primary',
    id: n.id,
  })),
  unreadNotifications: (state) => state.notifications.filter((n) => !n.viewedAt && !['SMS Usage'].includes(n.type)).map((n, index) => ({
    index,
    type: n.type,
    title: n.title || 'Notification',
    msg: n.text,
    icon: 'MessageSquareIcon',
    time: new Date(n.createdAt),
    category: 'primary',
    id: n.id,
  })),
  unreadSystemNotifications: (state) => state.notifications.filter((n) => !n.viewedAt && ['SMS Usage'].includes(n.type)).map((n, index) => ({
    index,
    type: n.type,
    title: n.title || 'Notification',
    msg: n.text,
    icon: 'AlertTriangleIcon',
    time: new Date(n.createdAt),
    category: 'danger',
    id: n.id,
  })),
  unreadNotificationsCount: (state) => state.unreadNotificationsCount,
  loginNotification: (state) => state.loginNotification,
};
