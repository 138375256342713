import { rjky } from '@/ky';
import { tap, hasKey } from '@/utils';
import * as types from '@/store/modules/funnels/mutation_types';
import * as cmp from '@/store/modules/companies/mutation_types';

export default {
  /**
   * Fetch a list of funnels for the current company
   *
   * @param  {Function} context.commit
   * @param  {String} filters
   * @return {Promise}
   */
  async fetchFunnels({ commit }, filters) {
    const funnels = await rjky.get(`surveyTemplates?${filters}`).json();
    return tap(funnels, (funnels) => commit(types.SET_FUNNELS, funnels));
  },

  /**
   * Fetch a funnel with the given id
   *
   * @param  {Function} context.commit
   * @param  {Integer} options.id
   * @param  {String} options.filters
   * @return {Promise}
   */
  async fetchFunnel({ commit }, { id, filters }) {
    try {
      const funnel = await rjky.get(`surveyTemplates/${id}?${filters}`).json();

      if (hasKey(funnel, 'company')) {
        commit(`companies/${cmp.SET_COMPANY}`, funnel.company || {}, { root: true });
        delete funnel.company;
      }

      commit(types.SET_FUNNEL, {
        ...funnel,
        fromEmail: funnel.message1EmailFromEmail,
        fromName: funnel.message1EmailFromName,
      });
    } catch (error) {
      throw new Error(error);
    }
  },

  /**
   * Creates a new funnel in storage
   *
   * @param  {Function} context.commit
   * @param  {Integer} companyId
   * @param  {Object} payload
   * @return {Promise}
   */
  async createFunnel({ commit }, { companyId, payload }) {
    try {
      const funnel = await rjky.post('surveyTemplates', { json: { ...payload, companyId } }).json();

      return tap(funnel, (funnel) => commit(types.ADD_FUNNEL, funnel));
    } catch (error) {
      const response = await error.response.json();
      return response;
    }
  },

  /**
   * Updates the funnel data in storage
   *
   * @param  {Function} context.commit
   * @param  {Integer} options.id
   * @param  {Object} options.payload
   * @return {Promise}
   */
  async updateFunnel({ commit }, { id, payload }) {
    try {
      const funnel = await rjky.patch(`surveyTemplates/${id}`, {
        json: payload,
      }).json();

      commit(types.SET_FUNNEL, funnel);
      return funnel;
    } catch (error) {
      const response = await error.response.json();
      return response;
    }
  },

  /**
   * Removes a funnel from storage
   *
   * @param  {Function} options.commit
   * @param  {Integer} id
   * @return {Promise}
   */
  async deleteFunnel({ commit }, id) {
    try {
      await rjky.delete(`surveyTemplates/${id}`).json();
      commit(types.DELETE_FUNNEL, id);
    } catch (error) {
      throw new Error(error);
    }
  },

  /**
   * Uploads the offer image for the funnel
   *
   * @param  {Function} context.commit
   * @param  {Integer} options.id
   * @param  {File} options.file
   * @return {Promise}
   */
  async updateOfferImage({ commit }, { id, file }) {
    try {
      const formData = new FormData();
      formData.append('offerImage', file);

      const { uploadedFilePath } = await rjky.post(`surveyTemplates/${id}/uploadOfferImage`, {
        body: formData,
      }).json();

      commit(types.UPDATE_FUNNEL_PROP, { prop: 'offerImage', value: uploadedFilePath });
    } catch (error) {
      throw new Error(error);
    }
  },
};
