import * as types from './mutation_types';

export default {
  [types.SET_ERROR](state, error) {
    state.error = error;
  },
  [types.UNSET_ERROR](state) {
    state.error = null;
  },
  [types.SET_INDUSTRY_DATA](state, industryData) {
    state.industryData = industryData;
  },
  [types.SET_PARTNER](state, partner) {
    state.partner = partner;
  },
  [types.SET_USER](state, user) {
    state.user = user;
  },
};
