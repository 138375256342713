<!--
 * File Name: App.vue
 * Description: Main vue file - APP
 * Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
 * Author: Pixinvent
 * Author URL: http://www.themeforest.net/user/pixinvent
-->

<template>
  <div
    id="app"
    :class="vueAppClasses"
  >
    <router-view @set-app-classes="setAppClasses" />

    <portal-target name="modals" />
  </div>
</template>

<script>
import _throttle from 'lodash/throttle';
import { themeConfig } from '@/../themeConfig';

export default {
  data() {
    return {
      vueAppClasses: [],
    };
  },
  watch: {
    '$store.state.theme': function(val) {
      this.toggleClassInBody(val);
    },
    '$vs.rtl': function(val) {
      document.documentElement.setAttribute('dir', val ? 'rtl' : 'ltr');
    },
  },
  mounted() {
    this.toggleClassInBody(themeConfig.theme);
    this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);

    const vh = window.innerHeight * 0.01;

    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },
  async created() {
    const dir = this.$vs.rtl ? 'rtl' : 'ltr';
    document.documentElement.setAttribute('dir', dir);

    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('scroll', this.handleScroll, { passive: true });
  },
  methods: {
    toggleClassInBody(className) {
      if (className === 'dark') {
        if (document.body.className.match('theme-semi-dark')) {
          document.body.classList.remove('theme-semi-dark');
        }
        document.body.classList.add('theme-dark');
      } else if (className === 'semi-dark') {
        if (document.body.className.match('theme-dark')) {
          document.body.classList.remove('theme-dark');
        }
        document.body.classList.add('theme-semi-dark');
      } else {
        if (document.body.className.match('theme-dark')) {
          document.body.classList.remove('theme-dark');
        }
        if (document.body.className.match('theme-semi-dark')) {
          document.body.classList.remove('theme-semi-dark');
        }
      }
    },
    setAppClasses(classesStr) {
      this.vueAppClasses.push(classesStr);
    },

    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    handleWindowResize: _throttle(function() {
      this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth);

      // Set --vh property
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    }, 450),

    /* eslint func-names: [0] */
    /* eslint no-invalid-this: 0 */
    handleScroll: _throttle(function() {
      this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY);
    }, 450),
  },
};

</script>
