import Vue from 'vue';
import Router from 'vue-router';

import AuthenticationRoutes from '@/views/auth/routes';
import RegistrationRoutes from '@/views/register/routes';
import AdminDashboardRoutes from '@/views/admin-dashboard/routes';
import CompanyDashboardRoutes from '@/views/company-dashboard/routes';
import PartnerDashboardRoutes from '@/views/partner-dashboard/routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home-view',
      component: () => import(/* webpackChunkName: "common" */ '@/views/RjHomeView.js'),
      alias: '/home',
    },
    ...AuthenticationRoutes,
    ...RegistrationRoutes,
    ...AdminDashboardRoutes,
    ...PartnerDashboardRoutes,
    ...CompanyDashboardRoutes,
    { path: '/verified/:company', component: () => import(/* webpackChunkName: "common" */ '@/views/RjVerifiedPage.vue') },
    { path: '/request-survey-permission/:token', component: () => import(/* webpackChunkName: "common" */ '@/views/RjPartnerSurveyPermissionPage.vue') },
    { path: '/unsubscribe/:locationId/:contactId', component: () => import(/* webpackChunkName: "common" */ '@/views/RjUnsubscribe.vue') },
    { path: '*', redirect: '/error-404' },
    { path: '/error-404', component: () => import(/* webpackChunkName: "common" */ '@/views/RjError404.vue') },
  ],
});


router.afterEach(() => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistration().then((reg) => {
      if (reg) {
        reg.update();
      }
    });
  }
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
