import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export const getDefaultState = () => ({
  error: null,
  partner: null,
  partners: [],
  plan: null,
  companies: [],
  archivedCompanies: [],
  hasValidPaymentMethod: true,
});

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  getters,
  mutations: {
    ...mutations,
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions,
};
