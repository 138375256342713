export const SET_PARTNERS = 'SET_PARTNERS';
export const SET_PARTNER = 'SET_PARTNER';
export const UNSET_PARTNER = 'UNSET_PARTNER';
export const SET_PARTNER_DETAILS = 'SET_PARTNER_DETAILS';
export const SET_PLAN = 'SET_PARTNER_PLAN';
export const UNSET_PLAN = 'UNSET_PLAN';
export const SET_COMPANIES = 'SET_COMPANIES';
export const UNSET_COMPANIES = 'UNSET_COMPANIES';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_CURRENT_COMPANY_ID = 'SET_CURRENT_COMPANY_ID';
export const UNSET_CURRENT_COMPANY_ID = 'UNSET_CURRENT_COMPANY_ID';
export const SET_CURRENT_COMPANY_USERS = 'SET_CURRENT_COMPANY_USERS';
export const UNSET_CURRENT_COMPANY_USERS = 'UNSET_CURRENT_COMPANY_USERS';
export const ADD_USER_TO_PARTNER = 'ADD_USER_TO_PARTNER';
export const REPLACE_USER_IN_PARTNER = 'REPLACE_USER_IN_PARTNER';
export const ADD_USER_TO_PARTNER_COMPANY = 'ADD_USER_TO_PARTNER_COMPANY';
export const REPLACE_USER_IN_PARTNER_COMPANY = 'REPLACE_USER_IN_PARTNER_COMPANY';
export const SET_PARTNER_COMPANY_DETAILS = 'SET_PARTNER_COMPANY_DETAILS';
export const ARCHIVE_COMPANY = 'ARCHIVE_COMPANY';
export const UNARCHIVE_COMPANY = 'UNARCHIVE_COMPANY';
export const SET_ARCHIVED_COMPANIES = 'SET_ARCHIVED_COMPANIES';
export const SET_HAS_VALID_PAYMENT_METHOD = 'SET_HAS_VALID_PAYMENT_METHOD';
export const RESET_STATE = 'RESET_STATE';
export const SET_ERROR = 'SET_ERROR';
export const UNSET_ERROR = 'UNSET_ERROR';
