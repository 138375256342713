import getters from './getters';

export default {
  namespaced: true,
  state: {
    countries: [
      {
        countryName: 'United States',
        countryShortCode: 'US',
        regions: [
          {
            name: 'Alabama',
            shortCode: 'AL',
          },
          {
            name: 'Alaska',
            shortCode: 'AK',
          },
          {
            name: 'American Samoa',
            shortCode: 'AS',
          },
          {
            name: 'Arizona',
            shortCode: 'AZ',
          },
          {
            name: 'Arkansas',
            shortCode: 'AR',
          },
          {
            name: 'California',
            shortCode: 'CA',
          },
          {
            name: 'Colorado',
            shortCode: 'CO',
          },
          {
            name: 'Connecticut',
            shortCode: 'CT',
          },
          {
            name: 'Delaware',
            shortCode: 'DE',
          },
          {
            name: 'District of Columbia',
            shortCode: 'DC',
          },
          {
            name: 'Florida',
            shortCode: 'FL',
          },
          {
            name: 'Georgia',
            shortCode: 'GA',
          },
          {
            name: 'Guam',
            shortCode: 'GU',
          },
          {
            name: 'Hawaii',
            shortCode: 'HI',
          },
          {
            name: 'Idaho',
            shortCode: 'ID',
          },
          {
            name: 'Illinois',
            shortCode: 'IL',
          },
          {
            name: 'Indiana',
            shortCode: 'IN',
          },
          {
            name: 'Iowa',
            shortCode: 'IA',
          },
          {
            name: 'Kansas',
            shortCode: 'KS',
          },
          {
            name: 'Kentucky',
            shortCode: 'KY',
          },
          {
            name: 'Louisiana',
            shortCode: 'LA',
          },
          {
            name: 'Maine',
            shortCode: 'ME',
          },
          {
            name: 'Maryland',
            shortCode: 'MD',
          },
          {
            name: 'Massachusetts',
            shortCode: 'MA',
          },
          {
            name: 'Michigan',
            shortCode: 'MI',
          },
          {
            name: 'Minnesota',
            shortCode: 'MN',
          },
          {
            name: 'Mississippi',
            shortCode: 'MS',
          },
          {
            name: 'Missouri',
            shortCode: 'MO',
          },
          {
            name: 'Montana',
            shortCode: 'MT',
          },
          {
            name: 'Nebraska',
            shortCode: 'NE',
          },
          {
            name: 'Nevada',
            shortCode: 'NV',
          },
          {
            name: 'New Hampshire',
            shortCode: 'NH',
          },
          {
            name: 'New Jersey',
            shortCode: 'NJ',
          },
          {
            name: 'New Mexico',
            shortCode: 'NM',
          },
          {
            name: 'New York',
            shortCode: 'NY',
          },
          {
            name: 'North Carolina',
            shortCode: 'NC',
          },
          {
            name: 'North Dakota',
            shortCode: 'ND',
          },
          {
            name: 'Ohio',
            shortCode: 'OH',
          },
          {
            name: 'Oklahoma',
            shortCode: 'OK',
          },
          {
            name: 'Oregon',
            shortCode: 'OR',
          },
          {
            name: 'Palau',
            shortCode: 'PW',
          },
          {
            name: 'Pennsylvania',
            shortCode: 'PA',
          },
          {
            name: 'Puerto Rico',
            shortCode: 'PR',
          },
          {
            name: 'Rhode Island',
            shortCode: 'RI',
          },
          {
            name: 'South Carolina',
            shortCode: 'SC',
          },
          {
            name: 'South Dakota',
            shortCode: 'SD',
          },
          {
            name: 'Tennessee',
            shortCode: 'TN',
          },
          {
            name: 'Texas',
            shortCode: 'TX',
          },
          {
            name: 'Utah',
            shortCode: 'UT',
          },
          {
            name: 'Vermont',
            shortCode: 'VT',
          },
          {
            name: 'Virgin Islands',
            shortCode: 'VI',
          },
          {
            name: 'Virginia',
            shortCode: 'VA',
          },
          {
            name: 'Washington',
            shortCode: 'WA',
          },
          {
            name: 'West Virginia',
            shortCode: 'WV',
          },
          {
            name: 'Wisconsin',
            shortCode: 'WI',
          },
          {
            name: 'Wyoming',
            shortCode: 'WY',
          },
        ],
      },
      {
        countryName: 'Canada',
        countryShortCode: 'CA',
        regions: [
          {
            name: 'Alberta',
            shortCode: 'AB',
          },
          {
            name: 'British Columbia',
            shortCode: 'BC',
          },
          {
            name: 'Manitoba',
            shortCode: 'MB',
          },
          {
            name: 'New Brunswick',
            shortCode: 'NB',
          },
          {
            name: 'Newfoundland and Labrador',
            shortCode: 'NL',
          },
          {
            name: 'Northwest Territories',
            shortCode: 'NT',
          },
          {
            name: 'Nova Scotia',
            shortCode: 'NS',
          },
          {
            name: 'Nunavut',
            shortCode: 'NU',
          },
          {
            name: 'Ontario',
            shortCode: 'ON',
          },
          {
            name: 'Prince Edward Island',
            shortCode: 'PE',
          },
          {
            name: 'Quebec',
            shortCode: 'QC',
          },
          {
            name: 'Saskatchewan',
            shortCode: 'SK',
          },
          {
            name: 'Yukon',
            shortCode: 'YT',
          },
        ],
      },
    ],
  },
  getters,
};
