import { tap } from '@/utils';
import { rjky, defaultky } from '@/ky';
import * as types from './mutation_types';

export default {
  /**
   * Fetch all locations for a given company
   *
   * @param  {Function} context.commit
   * @param  {String} filters
   * @return {Promise}
   */
  async fetchLocations({ commit }, filters) {
    const locations = await rjky.get(`locations?${filters}`).json();

    return tap(locations, (locations) => {
      commit(types.SET_LOCATIONS, locations);
    });
  },

  /**
   * Fetch a location with the given id
   *
   * @param  {Function} context.commit
   * @param  {String} payload.locationId
   * @param  {String} payload.filters
   * @return {Promise}
   */
  async fetchLocation({ commit }, { locationId, filters }) {
    const { locationReviewSites, ...location } = await rjky.get(`locations/${locationId}?${filters}`).json();
    commit(types.SET_LOCATION, location);
    commit(types.SET_REVIEW_SITES, locationReviewSites || []);

    return location;
  },

  /**
   * Updates the given location details
   *
   * @param  {Function} context.commit
   * @param  {Integer} options.locationId
   * @param  {Object} options.payload
   * @return {Promise}
   */
  async updateLocation({ commit }, { locationId, payload }) {
    try {
      const json = { ...payload, id: locationId };
      const location = await rjky.patch(`locations/${locationId}`, { json }).json();
      commit(types.UPDATE_LOCATION, location);
    } catch (error) {
      throw new Error(error);
    }
  },

  /**
   * Remove the location with the given id.
   *
   * @param  {Function} options.commit
   * @param  {Integer} locationId
   * @return {Promise}
   */
  async removeLocation({ commit }, locationId) {
    try {
      await rjky.delete(`locations/${locationId}`).json();
      commit(types.REMOVE_LOCATION, locationId);
    } catch (error) {
      throw new Error(error);
    }
  },

  /**
   * Deletes locations flagged as pending
   *
   * @param  {Object} context
   * @param  {Integer} companyId
   * @return {Promise}
   */
  async deletePending(context, companyId) {
    await rjky.get(`companies/${companyId}/deletePendingLocations`).json();
  },

  async charge(context, companyId) {
    try {
      await rjky.get(`companies/${companyId}/finalizePendingLocations`).json();
    } catch (error) {
      throw new Error(error);
    }
  },

  /**
   * Fetch the rating and amount of reviews for the given location connected review sites
   *
   * @param  {Function} context.dispatch
   * @param  {Function} context.commit
   * @param  {Object} context.getters
   * @param  {Integer} locationId
   * @return {Promise}
   */
  async fetchReviewSitesAnalytics({ dispatch, commit, getters }, locationId) {
    const lrsWithAnalytics = await Promise.all(getters.reviewSites.map(async(lrs) => {
      const payload = { locationId, reviewSiteId: lrs.reviewSite.id };
      const [{ average }, { count }] = await Promise.all([
        dispatch('fetchReviewSiteRating', payload),
        dispatch('fetchReviewSiteCount', payload),
      ]);

      return { ...lrs, rating: average, reviewCount: count };
    }));

    commit(types.SET_REVIEW_SITES, lrsWithAnalytics);
  },

  /**
   * Fetch the location rating on the given review site
   *
   * @param  {Integer} payload.locationId
   * @param  {Integer} payload.reviewSiteId
   * @return {Promise}
   */
  fetchReviewSiteRating(_, { locationId, reviewSiteId }) {
    const filters = JSON.stringify({
      field: 'rating',
      where: { locationId, reviewSiteId },
    });

    return rjky.get(`reviews/analytics/average?filter=${encodeURIComponent(filters)}`).json();
  },

  /**
   * Fetch the location number of reviews for the given reviewsite
   *
   * @param  {Integer} payload.locationId
   * @param  {Integer} payload.reviewSiteId
   * @return {Promise]}
   */
  fetchReviewSiteCount(_, { locationId, reviewSiteId }) {
    const filters = JSON.stringify({
      where: { locationId, reviewSiteId },
    });

    return rjky.get(`reviews/analytics/count?filter=${encodeURIComponent(filters)}`).json();
  },

  /**
   * Fetch the competitors for the given location
   *
   * @param  {Function} context.commit
   * @param  {Integer} locationId
   * @return {Promise}
   */
  async fetchCompetitors({ commit }, locationId) {
    const filters = JSON.stringify({
      where: { locationId },
    });

    const competitors = await rjky.get(`locationCompetitors?filter=${encodeURIComponent(filters)}`).json();
    commit(types.SET_COMPETITORS, competitors);
  },

  /**
   * Update the location 3Pack review sites
   *
   * @param  {Function} context.commit
   * @param  {Array} changes
   * @return {Promise}
   */
  async updateThreePack({ commit }, changes) {
    const locationReviewSites = await Promise.all(changes.map((site) => {
      const { id, priority } = site;
      return rjky.patch(`locationReviewSites/${id}`, { json: { priority } }).json();
    }));

    locationReviewSites.forEach(({ id, priority }) => commit(types.UPDATE_REVIEW_SITE, { id, priority }));
  },

  /**
   * Adds a review site to a location
   *
   * @param {Function} context.commit
   * @param {Integer} options.locationId
   * @param {Object} options.payload
   */
  async addReviewSite({ commit }, { locationId, payload }) {
    try {
      const json = { ...payload, locationId };
      const reviewSite = await rjky.post('locationReviewSites', { json }).json();
      commit(types.SET_REVIEW_SITE, reviewSite);
    } catch (error) {
      const response = await error.response.json();
      throw new Error(response.errorMessage);
    }
  },

  /**
   * Updates a location review site
   *
   * @param  {Function} options.commit
   * @param  {Object} options.getters
   * @param  {Object} payload
   * @return {Promise}
   */
  async updateReviewSite({ commit, getters }, payload) {
    const reviewSite = getters.reviewSites.find((lrs) => lrs.id === payload.id);

    const changes = Object.keys(payload).reduce((arr, key) => {
      let obj = {};
      if (reviewSite[key] !== payload[key] && key !== 'locationReviewSiteId') {
        obj = { ...arr, [key]: payload[key] };
      }
      return { ...arr, ...obj };
    }, {
      reviewSiteId: payload.reviewSiteId,
      locationId: payload.locationId,
    });

    try {
      await rjky.patch(`locationReviewSites/${payload.id}`, { json: changes }).json();
      commit(types.UPDATE_REVIEW_SITE, { id: payload.id, ...changes });
    } catch (error) {
      throw new Error(error);
    }
  },

  /**
   * Add a competitor for the given location
   *
   * @param {Function} options.commit
   * @param {Integer} options.locationId
   * @param {Object} options.payload
   */
  async addCompetitor({ commit }, { locationId, payload }) {
    const json = { ...payload, locationId };
    try {
      const competitor = await rjky.post('locationCompetitors', { json }).json();
      commit(types.SET_COMPETITOR, competitor);
    } catch (error) {
      throw new Error(error);
    }
  },

  /**
   * Updates the given location competitor
   *
   * @param  {Function} options.commit
   * @param  {Object} options.getters
   * @param  {Object} payload
   * @return {Promise}
   */
  async editCompetitor({ commit, getters }, payload) {
    const competitor = getters.competitors.find((c) => c.id === payload.locationCompetitorId);

    const changes = Object.keys(payload).reduce((arr, key) => {
      let obj = {};
      if (competitor[key] !== payload[key] && key !== 'locationCompetitorId') {
        obj = { ...arr, [key]: payload[key] };
      }
      return { ...arr, ...obj };
    }, {});

    try {
      await rjky.patch(`locationCompetitors/${competitor.id}`, { json: changes }).json();
      commit(types.UPDATE_COMPETITOR, { id: competitor.id, ...changes });
    } catch (error) {
      throw new Error(error);
    }
  },

  async getLocationReviewSitesForNap({ commit }, nap) {
    commit(types.UNSET_ERROR);
    try {
      return await rjky.post('locations/getLocationReviewSitesForNap', { json: { nap }, timeout: 30000 }).json();
    } catch (error) {
      commit(types.SET_ERROR, error);
      return null;
    }
  },

  async createTwilioNumber({ commit }, {
    twilioPhone, companyId, locationId,
  }) {
    commit(types.UNSET_ERROR);
    try {
      const response = await rjky.post('locations/createTwilioNumber', {
        json: { twilioNumber: twilioPhone, location: { companyId, id: locationId } },
      }).json();
      commit(types.UPDATE_LOCATION, { id: locationId, twilioPhone });
      return response;
    } catch (error) {
      const { twilioNumber } = await error.response.json();
      commit(types.SET_ERROR, twilioNumber.message);
      return twilioNumber;
    }
  },

  async getTwilioNumbers({ commit }, {
    country = 'US', zip = '', region = '', city = '', pattern = '',
  }) {
    commit(types.UNSET_ERROR);
    let filter = `country=${country}`;
    if (zip) {
      filter = `${filter}&zip=${zip}`;
    }
    if (region) {
      filter = `${filter}&region=${region}`;
    }
    if (city) {
      filter = `${filter}&city=${city}`;
    }
    if (pattern) {
      filter = `${filter}&pattern=${pattern.padEnd(10, '*')}`;
    }
    try {
      const { data } = await rjky.get(`locations/getTwilioNumbers?${filter}`).json();
      if (!data.length) {
        return [];
      }
      return data.map((item) => item[0].number);
    } catch (error) {
      commit(types.SET_ERROR, error);
      return [];
    }
  },

  async getFacebookPage(context, location) {
    const page = await defaultky.get(`https://graph.facebook.com/v9.0/${location.facebookPageId}?fields=name,location&access_token=${location.facebookAccessToken}`).json();

    let pageName = page.name;
    if (page.location?.city) {
      pageName = `${pageName} - ${page.location.city}`;
    }

    return { facebookPageId: page.id, name: pageName, accessToken: location.facebookAccessToken };
  },

  async exchangeFbToken(context, { pageId, userToken }) {
    const { accessToken } = await rjky.post('locations/exchangeFbToken', { json: { pageId, userToken } }).json();
    return accessToken;
  },
};
