import { rjky } from '@/ky';
import { tap } from '@/utils';
import * as types from '@/store/modules/users/mutation_types';

export default {
  /**
   * Fetch the users and associated data for a given company
   *
   * @param  {Function} context.commit
   * @param  {String} filters
   * @return {Promise}
   */
  async fetchUsers({ commit }, filters) {
    const users = await rjky.get(`users?${filters}`).json();
    commit(types.SET_USERS, users);
  },

  /**
   * Creates a new user.
   *
   * @param  {Function} context.commit
   * @param  {Object} payload
   * @return {Promise}
   */
  async createUser({ commit }, payload) {
    try {
      const user = await rjky.post('users', { json: payload }).json();

      return tap(user, (user) => commit(types.SET_USER, user));
    } catch (error) {
      return error.response.json();
    }
  },

  /**
   * Updates the given user details
   *
   * @param  {Function} context.commit
   * @param  {Integer} options.userId
   * @param  {Object} options.payload
   * @return {Promise}
   */
  async updateUser({ commit }, { userId, payload }) {
    try {
      const user = await rjky.patch(`users/${userId}`, { json: payload }).json();

      return tap(user, (user) => {
        const { updatedRoleMappings, ...rest } = user;
        const attributes = { ...rest, role: updatedRoleMappings[0].role };
        commit(types.UPDATE_USER, { userId: user.id, attributes });
      });
    } catch (error) {
      throw new Error(error);
    }
  },

  /**
   * Updates the locations to which the user has access to
   *
   * @param  {Function} context.commit
   * @param  {Integer} payload.userId
   * @param  {Object} payload.locations
   * @return {Promise}
   */
  async updateLocationAccessControls({ commit }, { userId, locations }) {
    const { updatedLocationAccessControl } = await rjky.post('users/updateLocationAccessControls', {
      json: { userId, locations },
    }).json();

    const attributes = { locationAccessControl: updatedLocationAccessControl };
    commit(types.UPDATE_USER, { userId, attributes });
  },

  /**
   * Removes a user from storage
   *
   * @param  {Function} context.commit
   * @param  {Integer} userId
   * @return {Promise}
   */
  async deleteUser({ commit }, userId) {
    try {
      await rjky.delete(`users/${userId}`).json();
      commit(types.DELETE_USER, userId);
    } catch (error) {
      throw new Error(error);
    }
  },

  /**
   * Resets user passowrd
   * @param  {Object} context
   * @param  {String} email
   * @return {Promise}
   */
  async resetPassword(context, email) {
    try {
      return await rjky.post('users/resetPassword', { json: { email } }).json();
    } catch (error) {
      throw new Error(error);
    }
  },

  async checkUserAvailability(context, email) {
    const { result, error } = await rjky.post('users/checkUserAvailability', { json: { email } }).json();
    if (error) {
      throw new Error(error);
    }
    return result;
  },
};
