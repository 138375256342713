import mutations from '@/store/modules/surveys/mutations';
import actions from '@/store/modules/surveys/actions';
import getters from '@/store/modules/surveys/getters';

export default {
  namespaced: true,
  state: {
    error: null,
  },
  getters,
  mutations,
  actions,
};
