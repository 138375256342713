export default [
  {
    path: '/register',
    component: () => import(/* webpackChunkName: "register" */'@/views/register/RjBaseRegisterView.js'),
    children: [
      {
        path: 'certified-partner',
        name: 'register-certified-partner',
        component: () => import(/* webpackChunkName: "register" */'@/views/register/RjRegisterCertifiedPartner.vue'),
      },
      {
        path: 'elite-partner',
        name: 'register-elite-partner',
        component: () => import(/* webpackChunkName: "register" */'@/views/register/RjRegisterElitePartner.vue'),
      },
      {
        path: 'elite-partner-free',
        name: 'register-elite-partner-free',
        beforeEnter() {
          window.location.replace('https://www.revenuejump.com/partners/');
        },
        // component: () => import(/* webpackChunkName: "register" */'@/views/register/RjRegisterElitePartner.vue'),
        // meta: {
        //   firstMonthFree: true,
        // },
      },
    ],
  },
];
