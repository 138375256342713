export const SET_LOCATION = 'SET_LOCATION';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const SET_COMPETITORS = 'SET_COMPETITORS';
export const SET_REVIEW_SITE = 'SET_REVIEW_SITE';
export const SET_REVIEW_SITES = 'SET_REVIEW_SITES';
export const SET_COMPETITOR = 'SET_COMPETITOR';
export const REMOVE_LOCATION = 'REMOVE_LOCATION';
export const UPDATE_COMPETITOR = 'UPDATE_COMPETITOR';
export const UPDATE_REVIEW_SITE = 'UPDATE_REVIEW_SITE';

// temp?
export const SET_ERROR = 'SET_ERROR';
export const UNSET_ERROR = 'UNSET_ERROR';
