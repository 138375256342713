/* eslint-disable */
export default {
  pages: {
    key: "title",
    data: [
      {
        title: 'Home',
        url: '/',
        icon: 'HomeIcon', 
        is_bookmarked: false
      },
      {
        title: 'Page 2',
        url: '/page2',
        icon: 'FileIcon',
        is_bookmarked: false
      },
    ]
  }
}
/* eslint-enable */
