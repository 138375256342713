import { rjky, defaultky } from '@/ky';
import { httpBuildQuery } from '@/utils';

import * as types from './mutation_types';
import * as partnerTypes from '../partners/mutation_types';

export default {
  /**
   * Fecth all companies
   *
   * @param  {Function} context.commit
   * @param  {String} filters
   * @return {Promise}
   */
  async getCompanies({ commit }, filters = null) {
    const companies = await rjky.get(`companies?${filters}`).json();
    commit(
      types.SET_COMPANIES,
      companies.filter((company) => company.plan),
    );
  },

  /**
   * Fetch all archived companies
   *
   * @param  {Function} context.commit
   * @param  {String} filters
   * @return {Promise}
   */
  async getArchived({ commit }, filters = null) {
    const archived = await rjky.get(`companies?${filters}`).json();
    commit(
      types.SET_ARCHIVED_COMPANIES,
      archived.filter((company) => company.plan),
    );
  },

  /**
   * Fetch a company with the given id
   *
   * @param  {Function} options.commit
   * @param  {Integer} options.companyId
   * @param  {String} options.filters
   * @return {Promise}
   */
  async getCompany({ commit }, { companyId, filters = '' }) {
    const company = await rjky.get(`companies/${companyId}?${filters}`).json();
    commit(types.SET_COMPANY, company);

    if (company.locations) {
      commit(`locations/${types.SET_LOCATIONS}`, company.locations || [], {
        root: true,
      });
    }

    if (company.partner) {
      commit(`partners/${partnerTypes.SET_PARTNER}`, company.partner || {}, {
        root: true,
      });
    }
  },

  async archive({ commit, getters }, companyId) {
    commit(types.UNSET_ERROR);
    try {
      await rjky.post(`companies/${companyId}/archive`).json();
      const { companies } = getters;
      commit(
        types.SET_COMPANIES,
        companies.filter((company) => company.id !== companyId),
      );
    } catch (error) {
      commit(types.SET_ERROR, error);
    }
  },

  async unarchive({ commit, rootGetters }, companyId) {
    commit(types.UNSET_ERROR);
    try {
      await rjky.post(`companies/${companyId}/unarchive`).json();

      const filter = {
        include: [
          'plan',
          'mainContact',
          {
            relation: 'locations',
            scope: {
              include: [
                'plan',
                {
                  relation: 'locationReviewSites',
                  scope: {
                    include: ['reviewSite'],
                  },
                },
              ],
            },
          },
          {
            relation: 'users',
            scope: {
              include: {
                relation: 'roleMappings',
                scope: {
                  include: ['role'],
                },
              },
            },
          },
        ],
      };
      const company = await rjky
        .get(`companies/${companyId}?filter=${JSON.stringify(filter)}`)
        .json();

      if (rootGetters['partners/partner']) {
        commit(`partners/${partnerTypes.UNARCHIVE_COMPANY}`, company, {
          root: true,
        });
      }
    } catch (error) {
      commit(types.SET_ERROR, error);
    }
  },

  async getPlans({ commit }) {
    commit(types.UNSET_ERROR);
    try {
      const plans = await rjky.get('plans').json();
      commit(types.SET_COMPANY_PLANS, plans);
    } catch (error) {
      commit(types.SET_ERROR, error);
    }
  },

  async getPlan({ commit }, planId) {
    try {
      const plan = await rjky.get(`plans/${planId}`).json();
      commit(types.SET_PLAN, plan);
    } catch (error) {
      commit(types.SET_ERROR, error);
    }
  },

  async getCompetitorReviewScoreHistory({ commit }, companyId) {
    commit(types.UNSET_ERROR);
    try {
      const filter = {
        where: {
          companyId,
        },
      };
      const scores = await rjky
        .get(`crsHistory?filter=${JSON.stringify(filter)}`)
        .json();
      return scores[0] || null;
    } catch (error) {
      commit(types.SET_ERROR, error);
      return null;
    }
  },

  async getReputationPerformanceGrade({ commit }, filter) {
    commit(types.UNSET_ERROR);
    try {
      return await rjky
        .get(`locations/analytics/rpg?filter=${JSON.stringify(filter)}`)
        .json();
    } catch (error) {
      commit(types.SET_ERROR, error);
      return null;
    }
  },

  async getCapturedReviewsCount({ commit }, filter) {
    commit(types.UNSET_ERROR);
    try {
      return await rjky
        .get(`reviews/analytics/count?filter=${JSON.stringify(filter)}`)
        .json();
    } catch (error) {
      commit(types.SET_ERROR, error);
      return null;
    }
  },

  async getRating({ commit }, filter) {
    commit(types.UNSET_ERROR);
    try {
      const { average } = await rjky
        .get(
          `reviews/analytics/average?filter=${JSON.stringify({
            ...filter,
            field: 'rating',
          })}`,
        )
        .json();
      return average;
    } catch (error) {
      commit(types.SET_ERROR, error);
      return null;
    }
  },

  async getRatingDistribution({ commit }, filter) {
    commit(types.UNSET_ERROR);
    try {
      return await rjky
        .get(
          `reviews/analytics/count?filter=${JSON.stringify({
            ...filter,
            by: 'stars',
          })}`,
        )
        .json();
    } catch (error) {
      commit(types.SET_ERROR, error);
      return null;
    }
  },

  async getTopSites({ commit }, filter) {
    commit(types.UNSET_ERROR);
    try {
      return await rjky
        .get(`reviews/analytics/topSites?filter=${JSON.stringify(filter)}`)
        .json();
    } catch (error) {
      commit(types.SET_ERROR, error);
      return null;
    }
  },

  async getSatisfactionScore({ commit }, filter) {
    commit(types.UNSET_ERROR);
    try {
      return await rjky
        .get(
          `surveys/analytics/count?filter=${JSON.stringify({
            ...filter,
            in: 'percent',
            field: 'sentiment',
          })}`,
        )
        .json();
    } catch (error) {
      commit(types.SET_ERROR, error);
      return null;
    }
  },

  async getCompletionRate({ commit }, filter) {
    commit(types.UNSET_ERROR);
    try {
      return await rjky
        .get(
          `surveys/analytics/count?filter=${JSON.stringify({
            ...filter,
            in: 'percent',
            field: 'completed',
          })}`,
        )
        .json();
    } catch (error) {
      commit(types.SET_ERROR, error);
      return null;
    }
  },

  async getVisitRate({ commit }, filter) {
    commit(types.UNSET_ERROR);
    try {
      return await rjky
        .get(
          `surveys/analytics/count?filter=${JSON.stringify({
            ...filter,
            in: 'percent',
            field: 'opened',
          })}`,
        )
        .json();
    } catch (error) {
      commit(types.SET_ERROR, error);
      return null;
    }
  },

  async getSurveysSent({ commit }, filter) {
    commit(types.UNSET_ERROR);
    try {
      return await rjky
        .get(`surveys/analytics/count?filter=${JSON.stringify(filter)}`)
        .json();
    } catch (error) {
      commit(types.SET_ERROR, error);
      return null;
    }
  },

  async getLocationReviews({ commit, getters }) {
    commit(types.UNSET_ERROR);
    try {
      const updatedLocations = await Promise.all(
        getters.locations.map(async(location) => ({
          reviews: await rjky
            .get(
              `reviews?filter=${encodeURIComponent(
                JSON.stringify({
                  where: {
                    locationId: location.id,
                  },
                }),
              )}`,
            )
            .json(),
          ...location,
        })),
      );
      commit(types.SET_LOCATIONS, updatedLocations);
    } catch (error) {
      commit(types.SET_ERROR, error);
    }
  },

  async getLocationSurveys({ commit, getters }) {
    commit(types.UNSET_ERROR);
    try {
      const updatedLocations = await Promise.all(
        getters.locations.map(async(location) => ({
          surveys: await rjky
            .get(
              `surveys?filter=${encodeURIComponent(
                JSON.stringify({
                  where: {
                    locationId: location.id,
                  },
                }),
              )}`,
            )
            .json(),
          ...location,
        })),
      );
      commit(types.SET_LOCATIONS, updatedLocations);
    } catch (error) {
      commit(types.SET_ERROR, error);
    }
  },

  /**
   * Updates the details a given company.
   *
   * @param  {Function} options.commit
   * @param  {Integer} options.companyId
   * @param  {Object} options.payload
   * @return {Promise}
   */
  async updateCompanyDetails({ commit }, { companyId, payload }) {
    try {
      const company = await rjky
        .patch(`companies/${companyId}`, {
          json: { ...payload, id: companyId },
        })
        .json();
      commit(types.SET_COMPANY_DETAILS, company);
    } catch (error) {
      throw new Error(error);
    }
  },

  /**
   * Fetch the total used sms for the current billing period and the amount allowed.
   *
   * @param  {Function} context.commit
   * @param  {Integer} companyId
   * @return {Promise}
   */
  async fetchSmsStatus({ commit }, companyId) {
    commit(types.SET_SMS_STATUS, { used: 0, max: 0 });
    const sms = await rjky.get(`companies/${companyId}/smsStatus`).json();
    commit(types.SET_SMS_STATUS, sms);
  },

  /**
   * Handles the fetching of company dependencies amounts
   *
   * @param  {Function} context.dispatch
   * @param  {Integer} companyId
   * @return {Promise}
   */
  withCounts({ dispatch }, companyId) {
    dispatch('fetchUsersCount', companyId);
    dispatch('fetchLocationsCount', companyId);
  },

  /**
   * Fetch the current amount of users for the given company
   *
   * @param  {Function} context.commit
   * @param  {Integer} companyId
   * @return {Promise}
   */
  async fetchUsersCount({ commit }, companyId) {
    const filters = httpBuildQuery({
      where: JSON.stringify({ companyId, active: true }),
    });

    const { count } = await rjky.get(`users/count?${filters}`).json();
    commit(types.SET_USERS_COUNT, count);
  },

  /**
   * Fetch the current amount of locations for the given company
   *
   * @param  {Function} context.commit
   * @param  {Integer} companyId
   * @return {Promise}
   */
  async fetchLocationsCount({ commit }, companyId) {
    const filters = httpBuildQuery({
      where: JSON.stringify({ companyId, active: true }),
    });

    const { count } = await rjky.get(`locations/count?${filters}`).json();
    commit(types.SET_LOCATIONS_COUNT, count);
  },

  /**
   * Updates the given company logo
   *
   * @param  {Function} options.commit
   * @param  {File} options.file
   * @param  {Integer} options.companyId
   * @return {Promise}
   */
  async updateCompanyLogo({ commit }, { file, companyId }) {
    try {
      const formData = new FormData();
      formData.append('LOGO_FILE', file);

      const { uploadedFilePath } = await rjky
        .post(`companies/${companyId}/logos`, { body: formData })
        .json();
      commit(types.SET_COMPANY_LOGO, uploadedFilePath);
    } catch (error) {
      const response = await error.response.json();
      throw new Error(response.message);
    }
  },

  async updateEmailSignature({ commit }, { companyId, payload }) {
    try {
      await rjky
        .patch(`companies/${companyId}`, {
          json: { ...payload, id: companyId },
        })
        .json();
      commit(types.SET_COMPANY_EMAIL_SIGNATURE, { ...payload });
    } catch (error) {
      throw new Error(error);
    }
  },

  async upgradeToHighJump({ commit, getters }, companyId) {
    commit(types.UNSET_ERROR);
    if (!companyId) {
      return null;
    }
    try {
      const { plan } = await rjky
        .get(`companies/${companyId}/upgradeToHighJump`)
        .json();
      commit(types.SET_COMPANY_DETAILS, {
        id: companyId,
        planId: plan.id,
        plan,
      });
      for (const location of getters.locations) {
        commit(types.SET_COMPANY_LOCATION_DETAILS, {
          id: location.id,
          planId: plan.id,
          plan,
        });
      }
      if (getters.location) {
        commit(types.SET_LOCATION, {
          ...getters.location,
          planId: plan.id,
          plan,
        });
      }
      commit(types.SET_PLAN, plan);
      return true;
    } catch (error) {
      const response = await error.response.json();
      commit(types.SET_ERROR, response.message);
      return null;
    }
  },

  async addHighJumpLocation({ commit, getters }, location) {
    commit(types.UNSET_ERROR);
    const companyId = getters.id;
    if (!companyId) {
      return null;
    }
    try {
      const { locationId } = await rjky
        .post(`companies/${companyId}/addHighJumpLocation`, {
          json: { data: location },
        })
        .json();
      return locationId;
    } catch (error) {
      const response = await error.response.json();
      commit(types.SET_ERROR, response.message);
      return null;
    }
  },

  async refreshGmbToken(context, companyId) {
    return rjky.get(`companies/${companyId}/refreshGmbToken`).json();
  },

  async saveGmbRefreshToken(context, { companyId, authCode }) {
    try {
      const response = await rjky
        .post(`companies/${companyId}/saveGmbRefreshToken`, {
          json: { authCode },
        })
        .json();
      return response;
    } catch (error) {
      const { message } = await error.response.json();
      return { success: false, message };
    }
  },

  async getGmbAccounts(context, accessToken) {
    return defaultky
      .get(
        `https://mybusinessaccountmanagement.googleapis.com/v1/accounts?access_token=${accessToken}`,
      )
      .json();
  },

  async getGmbLocations(context, { accessToken, accountName }) {
    const response = await defaultky
      .get(
        `https://mybusinessbusinessinformation.googleapis.com/v1/${accountName}/locations?access_token=${accessToken}&readMask=name,title,metadata.place_id,storefront_address.locality`,
      )
      .json();
    const allLocations = [];
    // eslint-disable-next-line prefer-const
    let { locations, nextPageToken } = response;
    allLocations.push(...locations);
    while (nextPageToken) {
      // eslint-disable-next-line no-await-in-loop
      const nextResponse = await defaultky
        .get(
          `https://mybusinessbusinessinformation.googleapis.com/v1/${accountName}/locations?access_token=${accessToken}&readMask=name,title,metadata.place_id,storefront_address.locality&pageToken=${nextPageToken}`,
        )
        .json();
      nextPageToken = nextResponse.nextPageToken;
      allLocations.push(...nextResponse.locations);
    }
    return { locations: allLocations };
  },

  async getWebhooks({ commit }, filters) {
    const webhooks = await rjky.get(`companyWebhooks?${filters}`).json();
    commit(types.SET_ACTIVE_CAMPAIGN_WEBHOOKS, webhooks);
    return webhooks;
  },

  async createWebhook({ dispatch }, payload) {
    await rjky.post('companyWebhooks', { json: payload });
    const filter = httpBuildQuery({
      filter: JSON.stringify({
        where: {
          companyId: payload.companyId,
        },
        include: ['user', 'location', 'surveyTemplate'],
      }),
    });
    await dispatch('getWebhooks', filter);
  },

  async updateWebhook({ dispatch }, payload) {
    await rjky.patch(`companyWebhooks/${payload.id}`, { json: payload });
    const filter = httpBuildQuery({
      filter: JSON.stringify({
        where: {
          companyId: payload.companyId,
        },
        include: ['user', 'location', 'surveyTemplate'],
      }),
    });
    await dispatch('getWebhooks', filter);
  },

  async deleteWebhook({ commit, dispatch }, payload) {
    await rjky.delete(`companyWebhooks/${payload.id}`);
    const filter = httpBuildQuery({
      filter: JSON.stringify({
        where: {
          companyId: payload.companyId,
        },
        include: ['user', 'location', 'surveyTemplate'],
      }),
    });
    const webhooks = await dispatch('getWebhooks', filter);
    if (!webhooks.length) {
      commit(types.SET_ACTIVE_CAMPAIGN_CONNECTED, false);
    }
  },

  async getIntegrationAccessTokens({ commit }, companyId) {
    const { tokens } = await rjky
      .get(`system/getIntegrationAccessToken/${companyId}`)
      .json();
    for (const token of tokens) {
      commit(types.SET_APP_TOKEN, { code: token.serviceCode, token: token.id });
    }
  },

  async generateIntegrationAccessToken({ commit, dispatch }, payload) {
    await rjky.post('system/generateAccessToken', { json: payload });
    await dispatch('getIntegrationAccessTokens', payload.companyId);
    commit(types.SET_APP_MANAGING, {
      code: payload.serviceCode,
      isManaging: true,
    });
  },

  async deleteIntegrationAccessToken(
    { commit, dispatch },
    { companyId, tokenId, serviceCode },
  ) {
    await rjky.post('system/deleteAccessToken', {
      json: { tokenId, serviceCode },
    });
    await dispatch('getIntegrationAccessTokens', companyId);
    commit(types.SET_APP_TOKEN, { code: serviceCode, token: null });
  },

  async saveInfusionsoftRefreshToken(
    context,
    { companyId, authCode, redirectUri },
  ) {
    try {
      const {
        success, accessToken, appId, cloudBridgeCredentialsId, message,
      } = await rjky
        .post(`companies/${companyId}/saveInfusionsoftRefreshToken`, {
          json: {
            authCode,
            redirectUri,
          },
        })
        .json();

      return {
        success,
        accessToken,
        appId,
        cloudBridgeCredentialsId,
        message,
      };
    } catch (error) {
      const { message } = await error.response.json();
      return { success: false, message };
    }
  },

  async getCloudBridgeCredentials(context, filters) {
    return rjky.get(`cloudBridgeCredentials?${filters}`).json();
  },

  async getCloudBridgeCredentialsById(context, id) {
    return rjky.get(`cloudBridgeCredentials/${id}`).json();
  },

  async updateCloudBridgeCredentials(context, { id, payload }) {
    return rjky.patch(`cloudBridgeCredentials/${id}`, { json: payload }).json();
  },

  async deleteCloudBridgeCredentials(context, id) {
    return rjky.delete(`cloudBridgeCredentials/${id}`).json();
  },

  async sendUpgradeRequest(context, companyId) {
    return rjky.post(`companies/${companyId}/sendUpgradeRequest`).json();
  },

  async sendAdditionalUsersRequest(context, companyId) {
    return rjky.get(`companies/${companyId}/sendAdditionalUsersRequest`).json();
  },

  async sendAdditionalSmsRequest(context, companyId) {
    return rjky.get(`companies/${companyId}/sendAdditionalSmsRequest`).json();
  },

  async authenticateEmailDomain(context, payload) {
    return rjky
      .post(`companies/${payload.companyId}/authenticateEmailDomain`, {
        json: payload,
      })
      .json();
  },

  async getEmailDomains(context, companyId) {
    return rjky.get(`companies/${companyId}/getEmailDomains`).json();
  },

  async verifyEmailDomain(context, payload) {
    return rjky
      .post(`companies/${payload.companyId}/verifyEmailDomain`, {
        json: payload,
      })
      .json();
  },

  async deleteEmailDomain(context, payload) {
    return rjky
      .post(`companies/${payload.companyId}/deleteEmailDomain`, {
        json: payload,
      })
      .json();
  },

  async sendSurveyPermissionRequest(context, { companyId, partnerUserId }) {
    return rjky
      .get(
        `companies/${companyId}/sendSurveyPermissionRequest?partnerUserId=${partnerUserId}`,
      )
      .json();
  },
};
