import {
  SET_ERROR,
  UNSET_ERROR,
  SET_ROLES,
} from './mutation_types';

export default {
  [SET_ERROR](state, error) {
    state.error = error;
  },
  [UNSET_ERROR](state) {
    state.error = null;
  },
  [SET_ROLES](state, roles) {
    state.roles = roles;
  },
};
