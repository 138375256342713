import mutations from '@/store/modules/locations/mutations';
import actions from '@/store/modules/locations/actions';
import getters from '@/store/modules/locations/getters';

export default {
  namespaced: true,
  state: {
    location: {},
    locations: [],
    reviewSites: [],
    competitors: [],
  },
  getters,
  mutations,
  actions,
};
