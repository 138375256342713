import Pipeline from '@/http/middleware/kernel/pipeline';

/**
 * Extracts the middleware from the current route macthed components
 *
 * @param  {array} routes
 * @return {array}
 */
const middlewaresFrom = (matched) => matched
  .map((matched) => matched.components.default.middleware)
  .filter((middleware) => middleware !== undefined)
  .flat();

export default function middleware(router, store) {
  router.beforeResolve((to, from, next) => {
    const middlewares = middlewaresFrom(to.matched);

    if (!middlewares.length) {
      return next();
    }

    const context = {
      to,
      from,
      next,
      router,
      store,
    };

    return middlewares[0]({ ...context, next: Pipeline(context, middlewares, 1) });
  });
}
