import Cookies from 'js-cookie';
import { rjky } from '@/ky';
import { SESSION_COOKIE } from '@/rj_consts';
import * as types from '@/store/modules/auth/mutation_types';

const secure = process.env.NODE_ENV === 'production';
const token = Math.random().toString(36).replace(/[^a-z]+/g, '');

export default {
  async login(_, credentials) {
    const accessToken = await rjky.post('users/signin', { json: credentials }).json();
    Cookies.set(SESSION_COOKIE, token, { expires: 1, secure, sameSite: 'lax' });
    return accessToken.redirectTo;
  },

  async me({ commit }) {
    const {
      user, role, permissions, redirectTo,
    } = await rjky.get('users/me').json();
    if (redirectTo) {
      window.location.href = redirectTo;
    }
    commit(types.SET_USER, { ...user, role, permissions });
    commit(types.USER_AUTHENTICATED, true);
  },

  async logout({ dispatch }) {
    await rjky.post('users/signout');
    await dispatch('clear');
  },

  async resetPassword(context, email) {
    try {
      const { result } = await rjky.post('users/resetPassword', { json: { email } }).json();
      return result;
    } catch (error) {
      return false;
    }
  },

  async resetPasswordAccept(context, { token, password }) {
    try {
      const { result } = await rjky.post('users/resetPasswordAccept', { json: { resetToken: token, password } }).json();
      return result;
    } catch (error) {
      return false;
    }
  },

  clear({ commit }) {
    commit(types.UNSET_USER);
    commit(types.USER_AUTHENTICATED, false);
    Cookies.remove(SESSION_COOKIE);
  },
};
