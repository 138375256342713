import actions from '@/store/modules/funnels/actions';
import getters from '@/store/modules/funnels/getters';
import mutations from '@/store/modules/funnels/mutations';

export default {
  namespaced: true,
  state: {
    funnel: null,
    funnels: [],
    media: {
      offerImage: null,
    },
    valid: true,
  },
  getters,
  mutations,
  actions,
};

