import { rjky } from '@/ky';

export default {
  async deleteReview(context, id) {
    return rjky.delete(`reviews/${id}`);
  },

  async updateReview(context, payload) {
    return rjky.patch(`reviews/${payload.id}`, { json: payload });
  },

  async getReviews(context, filter) {
    return rjky.get(`reviews?filter=${JSON.stringify(filter)}`).json();
  },
};
