export default {
  error: (state) => state.error,
  applications: (state) => state.applications,
  company: (state) => state.company,
  companies: (state) => state.companies,
  archivedCompanies: (state) => state.archivedCompanies,
  id: (state) => state.company?.id || null,
  users: (state) => state.users || [],
  locations: (state) => state.locations || [],
  plan: (state) => state.plan,
  addOns: (state) => state.addOns,
  sms: (state) => state.sms,
  surveys: (state) => state.surveys || [],
  surveyTemplates: (state) => state.surveyTemplates || [],
  stats: (state) => state.stats,
  location: (state) => state.location,
  plans: (state) => state.plans || [],
  extraUsersAddOns: (state) => state.addOns.filter(({ plan: { code } }) => code === 'Extra 5 Users'),
  extraSmsAddOns: (state) => state.addOns.filter(({ plan: { code } }) => code === 'Extra 500 SMS'),
  usersCount: (state) => state.usersCount,
  locationsCount: (state) => state.locationsCount,
  planUpgraded: (state) => state.planUpgraded,
};
