import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
  namespaced: true,
  state: {
    error: null,
    categories: [],
    reviewSites: [],
    timezones: [],
    features: {},
  },
  getters,
  mutations,
  actions,
};
