import { rjky } from '@/ky';
import { tap } from '@/utils';
import * as types from './mutations_types';

export default {
  async getWidgets(context, filter) {
    return rjky.get(`reviewsWidgets?filter=${JSON.stringify(filter)}`).json();
  },

  async getWidgetItems({ commit }, id) {
    const widget = await rjky.get(`reviewsWidgets/${id}/items`).json();
    commit(types.SET_WIDGET, widget);
  },

  async updateWidget({ getters }, payload) {
    return rjky.patch(`reviewsWidgets/${getters.widget.id}`, { json: payload }).json();
  },

  async createWidget({ commit }, payload) {
    const widget = await rjky.post('reviewsWidgets', { json: payload }).json();

    return tap(widget, (widget) => commit(types.SET_WIDGET, widget));
  },

  async deleteWidget(context, id) {
    await rjky.delete(`reviewsWidgets/${id}`);
  },
};
