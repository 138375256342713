<template functional>
  <div :class="['vx-breadcrumb', data.staticClass]">
    <ul class="flex flex-wrap items-center">
      <li
        v-for="(link, index) in props.items"
        :key="index"
        class="flex"
      >
        <template v-if="link.url">
          <router-link :to="link.url">
            {{ link.title }}
          </router-link>
        </template>

        <template v-else>
          <span class="'text-primary cursor-default'">
            {{ link.title }}
          </span>
        </template>

        <template v-if="index !== props.items.length - 1">
          <span class="breadcrumb-separator mx-2 flex items-center">
            <feather-icon
              icon="ChevronsRightIcon"
              svg-classes="w-4 h-4"
            />
          </span>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'RjBreadcrumb',
};
</script>
