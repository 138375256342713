import { tap } from '@/utils';
import * as types from './mutation_types';

export default {
  [types.SET_LOCATIONS](state, locations) {
    state.locations = locations;
  },

  [types.SET_LOCATION](state, location) {
    state.location = location;
  },

  [types.UPDATE_LOCATION](state, loc) {
    state.location = { ...state.location, ...loc };
    const index = state.locations.findIndex((l) => l.id === loc.id);
    if (index < 0) {
      return;
    }
    tap(state.locations[index], (location) => {
      state.locations.splice(index, 1, { ...location, ...loc });
    });
  },

  [types.REMOVE_LOCATION](state, locationId) {
    state.locations = state.locations.filter((location) => location.id !== locationId);
  },

  [types.SET_REVIEW_SITE](state, site) {
    state.reviewSites.push(site);
  },

  [types.SET_REVIEW_SITES](state, reviewSites) {
    state.reviewSites = reviewSites;
  },

  [types.UPDATE_REVIEW_SITE](state, site) {
    const index = state.reviewSites.findIndex((s) => s.id === site.id);

    tap(state.reviewSites[index], (reviewSite) => {
      state.reviewSites.splice(index, 1, { ...reviewSite, ...site });
    });
  },

  [types.SET_COMPETITORS](state, competitors) {
    state.competitors = competitors;
  },

  [types.SET_COMPETITOR](state, competitor) {
    state.competitors.push(competitor);
  },

  [types.UPDATE_COMPETITOR](state, competitor) {
    const index = state.competitors.findIndex((c) => c.id === competitor.id);

    tap(state.competitors[index], (cpt) => {
      state.competitors.splice(index, 1, { ...cpt, ...competitor });
    });
  },

  [types.SET_ERROR](state, error) {
    state.error = error;
  },

  [types.UNSET_ERROR](state) {
    state.error = null;
  },
};
