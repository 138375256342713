// import { tap } from '@/utils';
import * as types from './mutation_types';

export default {
  [types.SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications;
  },
  [types.SET_LOGIN_NOTIFICATION](state, notification) {
    state.loginNotification = notification;
  },
  [types.SET_UNREAD_NOTIFICATIONS_COUNT](state, count) {
    state.unreadNotificationsCount = count;
  },
};
