import getters from '@/store/modules/widgets/getters';
import actions from '@/store/modules/widgets/actions';
import mutations from '@/store/modules/widgets/mutations';

export default {
  namespaced: true,
  state: {
    widget: {
      sourceConfig: {},
      designConfig: {},
      name: '',
      items: [],
    },
    old: {
      name: '',
      sourceConfig: {},
      designConfig: {},
    },
    sampleItems: [
      {
        id: 1,
        customer: {
          name: 'John Doe',
          location: 'Boise, ID',
          avatar: require('@/assets/images/portrait/small/avatar-s-24.jpg'),
        },
        title: 'Amazing!!!',
        quote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam rutrum porttitor iaculis. Nunc augue dolor, gravida et nunc quis, eleifend feugiat odio. Aliquam consectetur, elit quis hendrerit malesuada, purus lectus pulvinar elit, nec mattis nibh nisi non ex. Cras convallis vehicula nisi, ut posuere nulla bibendum sed. Vestibulum convallis, est ut posuere viverra, augue justo sagittis dolor, vel pulvinar nisi risus eget purus.',
        itemType: 'review',
        reviewSite: {
          name: 'Google',
          code: 'google',
          id: 1,
          logoUrl: 'https://storage.googleapis.com/reviewjump-prod.appspot.com/review-site-photo-logos/google.png',
        },
        rating: 4.5,
        when: '40 minutes ago',
      },
      {
        id: 2,
        customer: {
          name: 'Jane Doe',
          location: 'Boise, ID',
          avatar: require('@/assets/images/portrait/small/avatar-s-16.jpg'),
        },
        title: 'Awesome Stuff',
        quote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        itemType: 'testimonial',
        rating: 5,
        when: '3 days ago',
      },
      {
        id: 3,
        customer: {
          name: 'Foo Bar',
          location: 'Boise, ID',
          avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'),
        },
        title: 'Loved it',
        quote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ut diam id sem volutpat auctor quis sit amet quam. Nunc tincidunt bibendum lectus molestie ultrices.',
        itemType: 'review',
        reviewSite: {
          name: 'Yelp',
          code: 'yelp',
          id: 3,
          logoUrl: 'https://storage.googleapis.com/reviewjump-prod.appspot.com/review-site-photo-logos/yelp.png',
        },
        rating: 4.5,
        when: '3 weeks ago',
      },
      {
        id: 4,
        customer: {
          name: 'Foo Bar',
          location: 'Boise, ID',
          avatar: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
        },
        title: 'I Recommend this',
        quote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ut diam id sem volutpat auctor quis sit amet quam.',
        itemType: 'testimonial',
        when: '2 months ago',
        rating: 4,
      },
    ],
  },
  getters,
  mutations,
  actions,
};
