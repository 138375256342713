import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import useNotificationsMapper from './useNotificationsMapper';

export default {
  namespaced: true,
  state: {
    error: null,
    roles: [],
    notificationRoles: {
      AdminAdministrator: [],
      CompanyAdmin: useNotificationsMapper([
        'receiveWeeklyRecap',
        'receiveNegativeReviewNotifications',
        'receiveNegativeFeedbackNotifications',
        'receiveTestimonialNotifications',
        'receiveSurveyReplyNotifications',
      ]),
      CompanyAnalyst: useNotificationsMapper(['receiveWeeklyRecap']),
      CompanyBilling: useNotificationsMapper([]),
      CompanyEmployee: [],
      CompanyManager: useNotificationsMapper([
        'receiveWeeklyRecap',
        'receiveNegativeReviewNotifications',
        'receiveNegativeFeedbackNotifications',
        'receiveTestimonialNotifications',
        'receiveSurveyReplyNotifications',
      ]),
      CompanyOperator: [],
      CompanyOwner: useNotificationsMapper([
        'receiveWeeklyRecap',
        'receiveNegativeReviewNotifications',
        'receiveNegativeFeedbackNotifications',
        'receiveTestimonialNotifications',
        'receiveSurveyReplyNotifications',
      ]),
      CompanyTech: [],
      PartnerManager: [],
    },
  },
  getters,
  mutations,
  actions,
};
