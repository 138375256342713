function pipeline(context, middlewares, index) {
  const middleware = middlewares[index];

  if (!middleware) {
    return context.next;
  }

  return () => {
    const next = pipeline(context, middlewares, index + 1);

    middleware({ ...context, next });
  };
}

export default pipeline;
