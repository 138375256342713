import { rjky } from '@/ky';
import { httpBuildQuery } from '@/utils';
import * as types from './mutation_types';

export default {
  async getCategories({ commit }) {
    const categories = await rjky.get('categories').json();
    commit(types.SET_CATEGORIES, categories);
  },

  async getReviewSites({ commit }) {
    const reviewSites = await rjky.get('reviewSites').json();
    commit(types.SET_REVIEW_SITES, reviewSites);
  },

  async getTimezones({ commit }) {
    const timezones = await rjky.get('timezones').json();
    timezones.sort((a, b) => a.name.localeCompare(b.name));
    commit(types.SET_TIMEZONES, timezones);
  },

  async getPlanFeatures({ commit }) {
    const filters = httpBuildQuery({
      filter: JSON.stringify({
        where: {
          or: [{ planId: 23 }, { planId: 24 }],
        },
      }),
    });

    const features = await rjky.get(`planFeatures?${filters}`).json();
    commit(types.SET_PLAN_FEATURES, features);
  },
};
