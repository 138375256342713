export const HIGH_JUMP_CODE = 'HighJump';
export const HIGH_JUMP_RETAIL_VALUE = 23300;
export const LOW_JUMP_CODE = 'LowJump';
export const LOW_JUMP_RETAIL_VALUE = 5200;
export const ELITE_PARTNER_PLAN = 'Elite Partner';
export const CERTIFIED_PARTNER_PLAN = 'Certified Partner';
export const EXTRA_5_USERS_CODE = 'Extra 5 Users';
export const EXTRA_500_SMS_CODE = 'Extra 500 SMS';
export const EXTRA_500_SMS_PACK_SIZE = 500;
export const SESSION_COOKIE = 'rj_session';
export const GOOGLE_REVIEW_SITE_CODE = 'google';
export const YELP_REVIEW_SITE_CODE = 'yelp';
export const MONITORED_REVIEW_SITES = [
  'avvo',
  'bbb',
  'citysearch',
  'dealerrater',
  'dexknows',
  'facebook',
  'google',
  'healthgrades',
  'homeadvisor',
  'lawyers',
  'tripadvisor',
  'truelocal',
  'vetratingz',
  'vitals',
  'yellowbook',
  'yellowpages',
  'yp',
  'yelp',
];
