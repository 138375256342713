import * as types from './mutation_types';

export default {
  [types.SET_PARTNERS](state, partners) {
    state.partners = partners;
  },
  [types.SET_PARTNER](state, partner) {
    state.partner = partner;
  },
  [types.UNSET_PARTNER](state) {
    state.partner = null;
  },
  [types.SET_PARTNER_DETAILS](state, details) {
    state.partner = {
      ...state.partner,
      ...details,
    };
  },
  [types.SET_PLAN](state, plan) {
    state.plan = plan;
  },
  [types.UNSET_PLAN](state) {
    state.plan = null;
  },
  [types.SET_COMPANIES](state, partnerCompanies) {
    state.partnerCompanies = partnerCompanies;
  },
  [types.UNSET_COMPANIES](state) {
    state.partnerCompanies = null;
  },
  [types.ADD_USER_TO_PARTNER](state, user) {
    state.partner.users = JSON.parse(
      JSON.stringify([...state.partner.users, user]),
    );
  },
  [types.REPLACE_USER_IN_PARTNER](state, user) {
    const { users } = state.partner;
    const index = users.findIndex((u) => u.id === user.id);
    users[index] = user;
    state.partner.users = JSON.parse(JSON.stringify(users));
  },
  [types.ADD_USER_TO_PARTNER_COMPANY](state, user) {
    const company = state.partnerCompanies.find((c) => c.id === user.companyId);
    if (!company) {
      return;
    }
    company.users = JSON.parse(JSON.stringify([...company.users, user]));
  },
  [types.REPLACE_USER_IN_PARTNER_COMPANY](state, user) {
    const company = state.partnerCompanies.find((c) => c.id === user.companyId);
    if (!company) {
      return;
    }
    const { users } = company;
    const index = users.findIndex((u) => u.id === user.id);
    users[index] = user;
    company.users = JSON.parse(JSON.stringify(users));
  },
  [types.SET_PAYMENT_METHOD](state, paymentMethod) {
    state.partner.paymentMethod = paymentMethod;
  },
  [types.SET_PARTNER_COMPANY_DETAILS](state, updatedCompany) {
    const index = state.partnerCompanies.findIndex((c) => c.id === updatedCompany.id);
    if (index < 0) {
      return;
    }
    const partnerCompanies = [...state.partnerCompanies];
    partnerCompanies[index] = {
      ...state.partnerCompanies[index],
      ...updatedCompany,
    };
    state.partnerCompanies = partnerCompanies;
  },
  [types.ARCHIVE_COMPANY](state, companyId) {
    const index = state.partnerCompanies.findIndex((c) => c.id === companyId);
    if (index < 0) {
      return;
    }
    const company = state.partnerCompanies[index];
    const partnerCompanies = [...state.partnerCompanies];
    partnerCompanies.splice(index, 1);
    state.partnerCompanies = partnerCompanies;
    const archivedCompanies = [...state.archivedCompanies, company];
    state.archivedCompanies = archivedCompanies;
  },
  [types.UNARCHIVE_COMPANY](state, company) {
    const partnerCompanies = [company, ...state.partnerCompanies];
    state.partnerCompanies = partnerCompanies;

    const index = state.archivedCompanies.findIndex((c) => c.id === company.id);
    if (index < 0) {
      return;
    }

    const archivedCompanies = [...state.archivedCompanies];
    archivedCompanies.splice(index, 1);
    state.archivedCompanies = archivedCompanies;
  },
  [types.SET_ARCHIVED_COMPANIES](state, companies) {
    state.archivedCompanies = companies;
  },
  [types.SET_HAS_VALID_PAYMENT_METHOD](state, isValid) {
    state.hasValidPaymentMethod = isValid;
  },
  [types.SET_ERROR](state, error) {
    state.error = error;
  },
  [types.UNSET_ERROR](state) {
    state.error = null;
  },
};
