import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import state from '@/store/vuexy/state';
import getters from '@/store/vuexy/getters';
import mutations from '@/store/vuexy/mutations';
import actions from '@/store/vuexy/actions';

// import partnerDashboard from '@/store/modules/partner-dashboard';
// import companyDashboard from '@/store/modules/company-dashboard';
import auth from '@/store/modules/auth';
import companies from '@/store/modules/companies';
import contacts from '@/store/modules/contacts';
import geography from '@/store/modules/geography';
import locations from '@/store/modules/locations';
import notifications from '@/store/modules/notifications';
import partners from '@/store/modules/partners';
import register from '@/store/modules/register';
import revenuejump from '@/store/modules/revenuejump';
import reviews from '@/store/modules/reviews';
import roles from '@/store/modules/roles';
import surveys from '@/store/modules/surveys';
import users from '@/store/modules/users';
import widgets from '@/store/modules/widgets';
import funnels from '@/store/modules/funnels';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth,
    companies,
    contacts,
    geography,
    locations,
    notifications,
    partners,
    register,
    revenuejump,
    reviews,
    roles,
    surveys,
    users,
    widgets,
    funnels,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
