export default [
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin-dashboard/RjAdminDashboard.vue'),
    children: [
      {
        path: '',
        name: 'admin-home',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin-dashboard/home/RjHome.vue'),
      },
      {
        path: 'partners',
        name: 'admin-partners',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin-dashboard/Partners.vue'),
      },
      {
        path: 'partners/:partner',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin-dashboard/partners/RjBasePartnerView.vue'),
        children: [
          {
            path: ':slug?',
            name: 'admin-partner',
            component: () => import(/* webpackChunkName: "admin" */ '@/views/admin-dashboard/partners/RjPartner.vue'),
            props: (route) => ({ slug: route.params.slug || null }),
          },
        ],
      },
      {
        path: 'companies',
        name: 'admin-companies',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin-dashboard/companies/RjCompanies.vue'),
      },
      {
        path: 'companies/:company',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin-dashboard/companies/RjBaseCompanyView.vue'),
        children: [
          {
            path: ':slug?',
            name: 'admin-company',
            component: () => import(/* webpackChunkName: "admin" */ '@/views/admin-dashboard/companies/RjCompany.vue'),
            props: (route) => ({ slug: route.params.slug || null }),
          },
          {
            path: 'locations/:location',
            name: 'admin-locations-settings',
            component: () => import(/* webpackChunkName: "admin" */ '@/views/admin-dashboard/locations/RjLocationSettings.vue'),
          },
          {
            path: 'funnels/:funnel',
            name: 'admin-funnel-settings',
            component: () => import(/* webpackChunkName: "admin" */ '@/views/admin-dashboard/funnels/RjFunnelSettings.vue'),
          },
        ],
      },
      {
        path: '/admin/reports',
        name: 'admin-reports',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin-dashboard/RjAdminReports.vue'),
      },
      {
        path: '/admin/settings',
        name: 'admin-settings',
        component: () => import(/* webpackChunkName: "admin" */ '@/views/admin-dashboard/Settings.vue'),
      },
    ],
  },
];
