export default [
  {
    path: '/company',
    component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/RjCompanyDashboard.vue'),
    children: [
      {
        path: '',
        name: 'company-home',
        component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/home/RjHome.vue'),
      },
      {
        path: 'surveys',
        name: 'company-surveys',
        component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/surveys/RjSurveys.vue'),
      },
      {
        path: 'details',
        name: 'company-details',
        component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/company/RjCompanyDetails.vue'),
      },
      {
        path: 'locations',
        name: 'company-locations',
        component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/locations/RjLocations.vue'),
      },
      {
        path: 'locations/:location',
        name: 'company-location',
        component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/locations/RjLocationSettings.vue'),
      },
      {
        path: 'users',
        name: 'company-users',
        component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/users/RjCompanyUsers.vue'),
      },
      {
        path: 'funnels',
        name: 'company-funnels',
        component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/funnels/RjFunnels.vue'),
      },
      {
        path: 'funnels/:funnel',
        name: 'company-funnel-settings',
        component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/funnels/RjFunnelSettings.vue'),
      },
      {
        path: 'email-settings',
        name: 'company-email-settings',
        component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/email-settings/RjEmailSettings.vue'),
      },
      {
        path: 'results',
        name: 'company-results',
        component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/results/RjResultsView.js'),
        children: [
          {
            path: 'testimonials',
            name: 'company-testimonials',
            component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/results/RjTestimonials.vue'),
          },
          {
            path: 'referrals',
            name: 'company-referrals',
            component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/results/RjReferrals.vue'),
          },
          {
            path: 'reviews',
            name: 'company-reviews',
            component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/results/RjReviews.vue'),
          },
          {
            path: 'negative-feedback',
            name: 'company-negative-feedback',
            component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/results/RjNegativeFeedback.vue'),
          },
          {
            path: 'notifications',
            name: 'company-notifications',
            component: () => import(/* webpackChunkName: "company" */ '@/views/common/notifications/RjNotifications.vue'),
          },
        ],
      },
      {
        path: 'integrations',
        name: 'company-integrations',
        component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/integrations/RjResultsView.js'),
        children: [
          {
            path: 'widgets',
            name: 'company-widgets',
            component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/integrations/widgets/RjWidgets.vue'),
          },
          {
            path: 'widgets/create',
            name: 'company-create-widget',
            component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/integrations/widgets/RjWidgetsBuilder.vue'),
          },
          {
            path: 'widgets/:widget/edit',
            name: 'company-edit-widget',
            component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/integrations/widgets/RjWidgetsBuilder.vue'),
            props: (route) => ({ widgetId: route.params.widget }),
          },
          {
            path: 'applications',
            name: 'company-apps',
            component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/integrations/apps/RjApplications.vue'),
          },
        ],
      },
      {
        path: 'help',
        name: 'company-support',
        component: () => import(/* webpackChunkName: "company" */ '@/views/company-dashboard/help/RjSupport.vue'),
      },
    ],
  },
];
