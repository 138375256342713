/*
 * File Name: themeConfig.js
 * Description: Theme configuration
 * Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
 * Author: Pixinvent
 * Author URL: http://www.themeforest.net/user/pixinvent
 */

// Main colors - Vuesax theme colors
import Vue from 'vue';
import Vuesax from 'vuesax';

const rjColors = {
  rjRed: '#ea5455',
  rjOrange: '#ff9f43',
  rjYellow: '#ecc94b', // Tailwind yellow-500
  rjGreen: '#28c76f',
  rjBlue: '#3caec8',
  rjPurple: '#5d58a6',
  rjGray500: '#94A3B8',
};

export const colors = {
  primary: rjColors.rjPurple,
  success: rjColors.rjOrange,
  danger: rjColors.rjRed,
  warning: rjColors.rjYellow,
  dark: '#1e1e1e',
  ...rjColors,
};

// Configs
export const themeConfig = {
  disableCustomizer: false, // options[Boolean] : true, false(default)
  disableThemeTour: true, // options[Boolean] : true, false(default)
  footerType: 'static', // options[String]  : static(default) / sticky / hidden
  hideScrollToTop: false, // options[Boolean] : true, false(default)
  mainLayoutType: 'vertical', // options[String]  : vertical(default) / horizontal
  navbarColor: '#fff', // options[String]  : HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
  navbarType: 'floating', // options[String]  : floating(default) / static / sticky / hidden
  routerTransition: 'zoom-fade', // options[String]  : zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
  rtl: false, // options[Boolean] : true, false(default)
  sidebarCollapsed: false, // options[Boolean] : true, false(default)
  theme: 'light', // options[String]  : "light"(default), "dark", "semi-dark"

  // Not required yet - WIP
  userInfoLocalStorageKey: 'userInfo',

  // NOTE: themeTour will be disabled in screens < 1200. Please refer docs for more info.
};
Vue.use(Vuesax, { theme: { colors }, rtl: themeConfig.rtl });

export default themeConfig;
