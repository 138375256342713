import { rjky } from '@/ky';
import * as types from './mutation_types';

const registerClient = rjky.extend({
  hooks: {
    beforeRequest: [
      async(request) => {
        request.headers.set(
          'Authorization',
          process.env.VUE_APP_STORE_ACCESS_TOKEN,
        );
      },
    ],
  },
});

export default {
  async createPartnerWithSubscription({ commit }, payload) {
    commit(types.UNSET_ERROR);
    try {
      const partnerPlans = await registerClient.get('partnerPlans').json();
      const plan = partnerPlans.find((p) => p.name === payload.planName);
      if (!plan) {
        throw new Error(`Partner plan not found: ${payload.planName}`);
      }
      const { data, success, message } = await registerClient
        .post('partners/createStripeSubscription', {
          json: { data: { ...payload, planId: plan.id } },
        })
        .json();
      if (!success) {
        commit(types.SET_ERROR, message);
        return;
      }
      commit(types.SET_PARTNER, data.partner);
      commit(types.SET_USER, data.user);
    } catch (error) {
      const response = await error.response.json();
      commit(types.SET_ERROR, response.message);
    }
  },

  async setIndustryData({ commit }, payload) {
    commit(types.SET_INDUSTRY_DATA, payload);
  },

  async sendPartnerSignupNotification({ commit, getters }) {
    commit(types.UNSET_ERROR);
    const { partner, user, industryData } = getters;
    const platforms = industryData.platforms || [];
    if (industryData.other) {
      platforms.push(industryData.other);
    }
    await registerClient.post('system/internalPartnerSignupNotification', {
      json: {
        companyPhone: partner.phone,
        country: partner.addressCountry,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        howLong: industryData.experience,
        howManyClients: industryData.clientsAmount,
        howManySignups: industryData.clientsProspects,
        industry: industryData.industry,
        location: {
          street: partner.addressStreet,
          city: partner.addressCity,
          state: partner.addressRegion,
          zip: partner.addressZip,
          phone: partner.phone,
        },
        partner,
        platforms,
        website: partner.website,
      },
    });
  },

  async updatePartner({ commit, getters }, payload) {
    commit(types.UNSET_ERROR);
    try {
      const { partnerId } = getters;
      let { name } = payload;
      if (!name) {
        name = getters.partner.name;
      }
      const partner = await rjky.patch(`partners/${partnerId}`, { json: { ...payload, id: partnerId, name } }).json();
      commit(types.SET_PARTNER, partner);
    } catch (error) {
      commit(types.SET_ERROR, error);
    }
  },

  async updateUser({ commit, getters }, payload) {
    commit(types.UNSET_ERROR);
    try {
      const { userId } = getters;
      const user = await rjky.patch(`users/${userId}`, { json: { ...payload, id: userId } }).json();
      commit(types.SET_USER, user);
    } catch (error) {
      commit(types.SET_ERROR, error);
    }
  },

  async createInHouseAccount({ commit, getters }) {
    commit(types.UNSET_ERROR);
    try {
      const { partnerId } = getters;
      await rjky.post(`partners/${partnerId}/createInHouseAccount`);
    } catch (error) {
      commit(types.SET_ERROR, error);
    }
  },

  async upgradeToFreeElite({ commit, getters }) {
    commit(types.UNSET_ERROR);
    try {
      const { partnerId } = getters;
      await rjky.get(`partners/${partnerId}/upgradeToFreeElite`).json();
    } catch (error) {
      commit(types.SET_ERROR, error);
    }
  },

  async sendNewPartnerNotification(context, partnerId) {
    return rjky.get(`partners/${partnerId}/sendNewPartnerNotification`).json();
  },
};
