import { isPristine } from '@/utils';

export default {
  widget: (state) => state.widget,
  sourceConfig: (state) => state.widget.sourceConfig,
  designConfig: (state) => state.widget.designConfig,
  sampleItems: (state) => state.sampleItems,
  pristine: (state) => {
    const pristineSourceConfig = isPristine(state.widget.sourceConfig, state.old.sourceConfig);
    const pristineDesignConfig = isPristine(state.widget.designConfig, state.old.designConfig);

    return pristineSourceConfig && pristineDesignConfig && (state.widget.name === state.old.name);
  },
};
