import router from '@/router';

export default async function expiredToken(request, options, response) {
  if (response.status === 401) {
    await router.app.$store.dispatch('auth/clear');
    return router.replace({ name: 'login' });
  }

  return response;
}
