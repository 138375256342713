import { rjky } from '@/ky';

export default {
  async deleteSurvey(context, id) {
    return rjky.delete(`surveys/${id}`).json();
  },

  async updateSurvey(context, payload) {
    return rjky.patch(`surveys/${payload.id}`, { json: payload }).json();
  },

  async countSurveys(context, filter) {
    return rjky.get(`surveys/count?where=${encodeURIComponent(JSON.stringify(filter))}`).json();
  },

  async getSurveys(context, filter) {
    return rjky.get(`surveys?filter=${encodeURIComponent(JSON.stringify(filter))}`).json();
  },

  async searchSurveys(context, filter) {
    return rjky.get(`surveys/search?filter=${encodeURIComponent(JSON.stringify(filter))}`).json();
  },

  async sendSurvey(context, survey) {
    try {
      const response = await rjky.post('surveys/submit', { json: survey }).json();
      return response;
    } catch (error) {
      const response = await error.response.json();
      throw response;
    }
  },

  async sendSurveys(context, surveys) {
    try {
      const response = await rjky.post('surveys/submitMultiple', { json: surveys }).json();
      return response;
    } catch (error) {
      const response = await error.response.json();
      throw response;
    }
  },

  async validateSurveyCsv(context, payload) {
    return rjky.post('contacts/validateCsv', {
      json: payload,
    }).json();
  },

  async deleteScheduledMessages(context, messages) {
    return Promise.all(messages.map(async(message) => {
      await rjky.delete(`surveyMessageSchedule/${message.id}`);
    }));
  },
};
