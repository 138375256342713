import Vue from 'vue';

/* eslint-disable no-param-reassign */
Vue.filter('title', (value, replacer = '_') => {
  if (!value) {
    return '';
  }
  value = value.toString();

  const arr = value.split(replacer);
  const capitalizedArray = [];
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalizedArray.push(capitalized);
  });
  return capitalizedArray.join(' ');
});
/* eslint-enable no-param-reassign */
