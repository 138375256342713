export default [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/RjLogin.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/RjForgotPassword.vue'),
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/RjResetPassword.vue'),
  },
];
