import { rjky } from '@/ky';
import { httpBuildQuery } from '@/utils';

import * as types from './mutation_types';

export default {
  /**
   * Fecth all companies
   *
   * @param  {Function} context
   * @param  {String} filters
   * @return {Promise}
   */
  async getNotifications(context, filters = null) {
    let notifications = [];
    if (!filters) {
      const filters = httpBuildQuery({
        filter: JSON.stringify({
          limit: 5,
          order: 'createdAt DESC',
        }),
      });
      notifications = await rjky.get(`notifications?${filters}`).json();
    } else {
      notifications = await rjky.get(`notifications?${httpBuildQuery({ filter: JSON.stringify(filters) })}`).json();
    }
    return notifications;
  },

  async getLoginNotification({ commit }) {
    const filters = httpBuildQuery({
      filter: JSON.stringify({
        where: {
          viewedAt: { eq: null },
          type: 'Login',
        },
        limit: 1,
        order: 'createdAt ASC',
      }),
    });
    const notifications = await rjky.get(`notifications?${filters}`).json();
    if (notifications.length) {
      commit(types.SET_LOGIN_NOTIFICATION, notifications[0]);
    }
  },

  async getTop5Notifications({ commit }) {
    const filters = httpBuildQuery({
      filter: JSON.stringify({
        where: {
          viewedAt: { eq: null },
          type: { neq: 'Login' },
        },
        limit: 5,
        order: 'createdAt DESC',
      }),
    });
    const notifications = await rjky.get(`notifications?${filters}`).json();
    commit(types.SET_NOTIFICATIONS, notifications);

    const where = JSON.stringify({
      active: true,
      viewedAt: { eq: null },
      type: { neq: 'Login' },
    });
    const { count } = await rjky.get(`notifications/count?where=${where}`).json();
    commit(types.SET_UNREAD_NOTIFICATIONS_COUNT, count);
  },

  async updateNotification(context, payload) {
    return rjky.patch(`notifications/${payload.id}`, { json: payload }).json();
  },

  async getIntegrationErrors() {
    return rjky.get('system/getIntegrationErrors').json();
  },

  async getRjApiErrors() {
    return rjky.get('system/getRjApiErrors').json();
  },
};
