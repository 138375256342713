<!--
 * File Name: FeatherIcon.vue
 * Description: Feather icon component. Creates feather icon based on 'icon' prop
 * Component Name: FeatherIcon
 * Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
 * Author: Pixinvent
 * Author URL: http://www.themeforest.net/user/pixinvent
-->

<script>
import * as icons from 'vue-feather-icons';

export default {
  name: 'FeatherIcon',
  functional: true,
  props: {
    icon: {
      type: String,
      required: true,
    },
    svgClasses: {
      type: [String, Object, Array],
      default: '',
    },
    badge: {
      type: Number,
      default: undefined,
    },
  },
  render(h, { props, data }) {
    // Add feather classes
    /* eslint-disable no-param-reassign */
    data.staticClass ? data.staticClass = `${data.staticClass} feather-icon select-none relative` : data.staticClass = 'feather-icon select-none relative';
    /* eslint-enable no-param-reassign */

    const svg = h(icons[props.icon], { class: props.svgClasses });
    const badgeEl = h('span', { class: 'feather-icon-badge bg-red-600 text-white h-5 w-5 absolute rounded-full text-xs flex items-center justify-center', style: 'top: -2px; right: 2px' }, [props.badge]);
    const children = [svg];
    if (props.badge) {
      children.push(badgeEl);
    }
    return h('span', data, children);
  },
};

</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/featherIcon.scss"
</style>
