const map = {
  receiveWeeklyRecap: 'Weeky Recap',
  receiveNegativeReviewNotifications: 'Negative Review',
  receiveNegativeFeedbackNotifications: 'Negative Feedback',
  receiveTestimonialNotifications: 'Testimonial',
  receiveBillingNotifications: 'Billing',
  receiveSurveyReplyNotifications: 'Survey Reply',
};

export default function mapper(notifications) {
  return notifications.reduce((arr, notification) => [...arr, { value: notification, label: map[notification] }], []);
}
