export const SET_COMPANY = 'SET_COMPANY';
export const UNSET_COMPANY = 'UNSET_COMPANY';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_ARCHIVED_COMPANIES = 'SET_ARCHIVED_COMPANIES';
export const SET_COMPANY_CATEGORIES = 'SET_COMPANY_CATEGORIES';
export const SET_COMPANY_PLANS = 'SET_COMPANY_PLANS';
export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS';
export const SET_COMPANY_EMAIL_SIGNATURE = 'SET_COMPANY_EMAIL_SIGNATURE';
export const SET_LOCATION = 'SET_LOCATION';
export const UNSET_LOCATION = 'UNSET_LOCATION';
export const SET_COMPANY_LOGO = 'SET_COMPANY_LOGO';
export const SET_COMPANY_USERS = 'SET_COMPANY_USERS';
export const UNSET_COMPANY_USERS = 'UNSET_COMPANY_USERS';
export const SET_SURVEY_TEMPLATES = 'SET_SURVEY_TEMPLATES';
export const UNSET_SURVEY_TEMPLATES = 'UNSET_SURVEY_TEMPLATES';
export const SET_COMPANY_SURVEYS = 'SET_COMPANY_SURVEYS';
export const ADD_COMPANY_SURVEY_TEMPLATE = 'ADD_COMPANY_SURVEY_TEMPLATE';
export const DELETE_COMPANY_SURVEY_TEMPLATE = 'DELETE_COMPANY_SURVEY_TEMPLATE';
export const ADD_USER_TO_COMPANY = 'ADD_USER_TO_COMPANY';
export const REPLACE_USER_IN_COMPANY = 'REPLACE_USER_IN_COMPANY';
export const SET_COMPANY_STATS = 'SET_COMPANY_STATS';
export const UNSET_COMPANY_STATS = 'UNSET_COMPANY_STATS';
export const SET_OFFER_IMAGE_URL = 'SET_OFFER_IMAGE_URL';
export const SET_COMPANY_LOCATION_DETAILS = 'SET_COMPANY_LOCATION_DETAILS';
export const SET_REVIEW_SITES = 'SET_REVIEW_SITES';
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const UNSET_LOCATIONS = 'UNSET_LOCATIONS';
export const SET_PLAN = 'SET_PLAN';
export const SET_APPLICATIONS = 'SET_APPLICATIONS';
export const UPDATE_LOCATION_FACEBOOK_PAGE = 'UPDATE_LOCATION_FACEBOOK_PAGE';
export const SET_APP_TOKEN = 'SET_APP_TOKEN';
export const SET_FACEBOOK_CONNECTED = 'SET_FACEBOOK_CONNECTED';
export const SET_FACEBOOK_AUTH_RESPONSE = 'SET_FACEBOOK_AUTH_RESPONSE';
export const SET_GOOGLE_CONNECTED = 'SET_GOOGLE_CONNECTED';
export const SET_GOOGLE_ACCESS_TOKEN = 'SET_GOOGLE_ACCESS_TOKEN';
export const SET_ACTIVE_CAMPAIGN_CONNECTED = 'SET_ACTIVE_CAMPAIGN_CONNECTED';
export const SET_ACTIVE_CAMPAIGN_WEBHOOKS = 'SET_ACTIVE_CAMPAIGN_WEBHOOKS';
export const SET_INFUSIONSOFT_ACCESS_TOKEN = 'SET_INFUSIONSOFT_ACCESS_TOKEN';
export const SET_INFUSIONSOFT_TAGGING = 'SET_INFUSIONSOFT_TAGGING';
export const SET_INFUSIONSOFT_CONNECTED = 'SET_INFUSIONSOFT_CONNECTED';
export const SET_APP_CONNECTED = 'SET_APP_CONNECTED';
export const SET_APP_MANAGING = 'SET_APP_MANAGING';
export const SET_ERROR = 'SET_ERROR';
export const UNSET_ERROR = 'UNSET_ERROR';
export const SET_SMS_STATUS = 'SET_SMS_STATUS';
export const SET_USERS_COUNT = 'SET_USERS_COUNT';
export const SET_LOCATIONS_COUNT = 'SET_LOCATIONS_COUNT';
export const RESET_STATE = 'RESET_STATE';
export const SET_PLAN_UPGRADED = 'SET_PLAN_UPGRADED';
