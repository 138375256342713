<template>
  <v-select
    append-to-body
    :value="value"
    :calculate-position="position"
    v-bind="$attrs"
    v-on="$listeners"
    @input="(value) => $emit('input', value)"
  />
</template>

<script>
import vSelect from 'vue-select';
import { createPopper } from '@popperjs/core';

export default {
  name: 'RjSelect',

  components: { vSelect },

  inheritAttrs: false,

  props: {
    value: {
      required: true,
      validator: () => true,
    },
  },

  methods: {
    /* eslint no-param-reassign: ["error", { "props": false }] */
    position(dropdownList, component, { width }) {
      dropdownList.style.width = width;

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: { offset: [0, -1] },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top');
            },
          },
        ],
      });

      return () => popper.destroy();
    },
  },
};
</script>
