import ky from 'ky';
import ExpiredToken from '@/http/interceptors/ExpiredToken';

export const defaultky = ky;

export const rjky = ky.extend({
  credentials: 'include',
  retry: 1,
  prefixUrl: process.env.VUE_APP_RJ_API_URL,
  throwHttpErrors: true,
  hooks: {
    afterResponse: [ExpiredToken],
  },
  timeout: false,
});
