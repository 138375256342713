import * as types from '@/store/modules/auth/mutation_types';

export default {
  [types.SET_USER](state, user) {
    const { role, permissions, ...auth } = user;

    state.role = role;
    state.user = auth;
    state.permissions = permissions;
  },

  [types.UNSET_USER](state) {
    state.role = '';
    state.user = {};
    state.permissions = {};
  },

  [types.USER_AUTHENTICATED](state, authenticated) {
    state.authenticated = authenticated;
  },
};
