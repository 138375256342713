import { rjky } from '@/ky';
import {
  SET_ROLES,
  SET_ERROR,
  UNSET_ERROR,
} from './mutation_types';

export default {
  async getRoles({ commit }) {
    commit(UNSET_ERROR);
    try {
      const roles = await rjky
        .get(
          'roles',
        )
        .json();
      commit(SET_ROLES, roles);
    } catch (error) {
      commit(SET_ERROR, error);
    }
  },
};
