<template functional>
  <div
    class="vx-input-group flex"
    :class="data.staticClass"
  >
    <!-- Slot: prepend -->
    <div
      v-if="slots().prepend"
      class="vx-input-group-prepend flex"
      :class="props.prependClasses"
    >
      <slot name="prepend" />
    </div>
    <!-- Slot: default -->
    <div class="vx-input-group-default flex-grow">
      <slot />
    </div>
    <!-- Slot: append -->
    <div
      v-if="slots().append"
      class="vx-input-group-append flex"
      :class="props.appendClasses"
    >
      <slot name="append" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'VxInputGroup',
  props: {
    prependClasses: {
      type: String,
      default: '',
    },
    appendClasses: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/vxInputGroup.scss";
</style>
