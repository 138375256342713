export default {
  error: (state) => state.error,
  partners: (state) => state.partners,
  partner: (state) => state.partner,
  plan: (state) => state.plan,
  companies: (state) => state.partnerCompanies,
  commission: (state) => state.partner?.commission || { baseAmount: 0, bonusAmount: 0 },
  eliteBonusEligible: (state) => state.partner?.eliteBonusEligible || false,
  archivedCompanies: (state) => state.archivedCompanies,
  paymentMethod: (state) => {
    if (!state.partner?.paymentMethod) {
      return null;
    }
    const { paymentMethod } = state.partner;
    return {
      brand: paymentMethod.brand,
      nameOnCard: paymentMethod.name,
      expiryMonth: paymentMethod.exp_month,
      expiryYear: paymentMethod.exp_year,
      last4: paymentMethod.last4,
    };
  },
  hasValidPaymentMethod: (state) => state.hasValidPaymentMethod,
  renewalAmount: (state, getters) => {
    let total = 0;
    for (const item of getters.subscriptionItems) {
      total += item.price.unit_amount * item.quantity;
    }
    return total;
  },
  renewalDate: (state, getters) => {
    if (getters.subscription?.current_period_end) {
      return new Date(getters.subscription.current_period_end * 1000);
    }
    return null;
  },
  subscription: (state) => state.partner?.subscriptions?.[0] || null,
  subscriptionItems: (state, getters) => getters.subscription?.items?.data || [],
  inHouseCompany: (state, getters, rootState, rootGetters) => rootGetters['companies/companies'].find((c) => c.id === state.partner.demoCompanyId),
};
